import { useApolloClient } from '@apollo/client';
import { FunctionComponent, useContext, useState } from 'react';
import UserContext from '../context/UserContext';
import NotificationList, {
  GET_UNREAD_NOTIFICATIONS_QUERY,
  UnreadNotificationsQueryResult,
  UnreadNotificationsQueryVariables
} from './NotificationList';
import Sidebar from './Sidebar';
import TopBar from './TopBar';

interface MainContentProps {
  currentSection?: string;
  mainPadding?: string;
  navbarBackgroundColor?: string;
}

const MainContent: FunctionComponent<MainContentProps> = ({
  children,
  currentSection,
  mainPadding,
  navbarBackgroundColor
}) => {
  const [menuDisplayed, setMenuDisplayed] = useState(false);
  const [notificationListDisplayed, setNotificationListDisplayed] = useState(false);
  const user = useContext(UserContext);
  const apolloClient = useApolloClient();

  let unreadNotifications: UnreadNotificationsQueryResult | null = null;
  try {
    unreadNotifications = apolloClient.readQuery<UnreadNotificationsQueryResult, UnreadNotificationsQueryVariables>({
      variables: { id: user?.id ?? '' },
      query: GET_UNREAD_NOTIFICATIONS_QUERY
    });
  } catch (error) {
    //ignore
  }
  const newNotifications = unreadNotifications ? unreadNotifications.notifications.filter(n => !n.read).length : 0;

  return (
    <>
      <div className='children-of-layout relative'>
        <Sidebar
          currentSection={currentSection}
          menuDisplayed={menuDisplayed}
          onCloseMenu={() => setMenuDisplayed(false)}
          navbarBackgroundColor={navbarBackgroundColor}
          notificationListDisplayed={notificationListDisplayed}
          newNotifications={newNotifications}
          setNotificationListDisplayed={setNotificationListDisplayed}
        />
        <NotificationList
          setNotificationListDisplayed={setNotificationListDisplayed}
          notificationListDisplayed={notificationListDisplayed}
          setMenuDisplayed={setMenuDisplayed}
        />
        <TopBar
          onOpenMenu={setMenuDisplayed}
          onChangeShowNotifications={setNotificationListDisplayed}
          showNotifications={notificationListDisplayed}
          newNotifications={newNotifications}
        />
        <div className='main-container'>
          <main style={{ padding: mainPadding || 0, position: 'relative' }}>{children}</main>
        </div>
      </div>

      <style jsx>{`
        main {
          margin-left: 70px;
        }

        @media screen and (max-width: 640px) {
          main {
            margin-top: 58px;
          }

          main {
            margin-left: 0;
          }
        }
      `}</style>
    </>
  );
};

export default MainContent;
