import { ChatMessage } from 'generated/schema-types';

export interface MessageOptionsProps {
  message: ChatMessage;
  onDeleteMessage: (arg0: string) => void;
}

const MessageOptions: React.FC<MessageOptionsProps> = ({ message, onDeleteMessage: onDeleteMessage }) => {
  return (
    <div className='wrapper flex text-center flex-col rounded-lg p-6'>
      <span className='row-1'>Opciones</span>
      <div className='buttons flex flex-col my-4'>
        <button className='g-orange-button' onClick={(): void => onDeleteMessage(message.id)}>
          Eliminar
        </button>
      </div>
      <style jsx>{`
        .wrapper {
          background-color: #fdfdfd;
        }
      `}</style>
    </div>
  );
};

export default MessageOptions;
