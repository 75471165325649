import RawSpinner from 'components/RawSpinner';
import { useNotification } from 'context/NotificationContext';
import { ChatMessage } from 'generated/schema-types';
import moment from 'moment';
import { ChangeEvent, Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { UserIcon } from '../../../public/icons';
import ArrowDown from '../../../public/icons/arrow-down.svg';
import Clip from '../../../public/icons/clip.svg';
import LeftArrow from '../../../public/icons/left-arrow.svg';
import Send from '../../../public/icons/send.svg';
import ProtectedImage from '../ProtectedImage';
import DateMessage from './DateMessage';
import Message from './Message';

function scrollBottom(): void {
  const el = document.getElementById('scroller');
  if (el !== null) {
    el.scrollTop = el.scrollHeight;
  }
}
export interface ActiveConversationProps {
  name: string;
  photo: string | null;
  messages: ChatMessage[];
  inputMessage: string;
  onInputMessageChange: (input: string) => void;
  onSend: () => void;
  onSendAttachment: (file: File) => void;
  onBack: () => void;
  hideConversations: boolean;
  onScrollTop: () => void;
  loadingMoreMessages: boolean;
  onDeleteMessage: (id: string) => Promise<void>;
  loadingNewMessages: boolean;
  totalMessages: number;
  sendingMessage: boolean;
  isFromMyself: (message: ChatMessage) => boolean;
}

const ActiveConversation: FunctionComponent<ActiveConversationProps> = ({
  name,
  photo,
  messages,
  inputMessage,
  onInputMessageChange,
  onSend,
  onSendAttachment,
  onBack,
  hideConversations,
  onScrollTop,
  onDeleteMessage,
  loadingMoreMessages,
  loadingNewMessages,
  totalMessages,
  sendingMessage,
  isFromMyself
}) => {
  const messageInputElement = useRef<HTMLTextAreaElement>(null);
  const [lastScrollPosition, setScrollPosition] = useState(0);
  const lastScrollPositionRef = useRef(lastScrollPosition);
  const [inTop, setInTop] = useState(false);
  const { newFeedbackNotification } = useNotification();

  /**
   * Side effects
   */
  // This effect keeps the scroll to the bottom if you are near it or if the message is from yourself
  const mostRecentMessage = messages[messages.length - 1];
  useEffect(() => {
    const scroller = document.getElementById('scroller');
    if (
      (mostRecentMessage && isFromMyself(mostRecentMessage)) ||
      (scroller?.scrollHeight ?? 0) - 1500 <= (scroller?.scrollTop ?? 0)
    ) {
      scrollBottom();
    }
  }, [mostRecentMessage ? mostRecentMessage.id : null]);

  // This effects only keeps the ref's value in sync
  useEffect(() => {
    lastScrollPositionRef.current = lastScrollPosition;
  }, [lastScrollPosition]);

  // Effect that mantains scroll position when new messages appear
  useEffect(() => {
    const scroller = document.getElementById('scroller');
    if (loadingMoreMessages) {
      setScrollPosition((scroller?.scrollHeight ?? 0) - (scroller?.scrollTop ?? 0));
    } else {
      if (scroller) {
        scroller.scrollTop = scroller?.scrollHeight ?? 0 - lastScrollPositionRef.current;
      }
    }
  }, [loadingMoreMessages]);

  // Scroll to the bottom
  useEffect(() => {
    scrollBottom();
  }, []);

  /**
   * Events handlers and helpers
   */
  const onScroll = (): void => {
    if (loadingMoreMessages) return;
    const scroller = document.getElementById('scroller');
    if (scroller?.scrollTop === 0) {
      onScrollTop();
    }
    setInTop((scroller?.scrollHeight ?? 0) - (scroller?.scrollTop ?? 0) > 1500);
  };

  const fileListToArray = (list: FileList): (File | null)[] => {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  };

  const sendAttachment = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!e.target.files) return;
    fileListToArray(e.target.files).forEach(file => {
      if (!file) return;

      if (file.size > 10000000) {
        newFeedbackNotification({
          level: 'error',
          message: 'El archivo no debe exceder los 10MB'
        });
        return;
      }

      onSendAttachment(file);
    });
  };

  return (
    <div className={hideConversations ? 'active-conversation' : 'active-conversation hide'}>
      <section className='person flex justify-left p-2'>
        <div className='return-button my-auto mr-3 cursor-pointer hover:' onClick={onBack}>
          <LeftArrow fill='#02b9cf' height='40' width='20' />
        </div>
        <div className='user-photo flex items-center'>
          {photo ? (
            <div className='h-12 w-12 flex justify-center items-center'>
              <ProtectedImage filename={photo} className='rounded-full h-10 w-10' objectFit='cover' size='60x60' />
            </div>
          ) : (
            <UserIcon height='2.5rem' width='2.5rem' fill='rgb(150,150,150)' />
          )}
          <div className='conversation-info flex flex-col items-left ml-2 justify-center'>
            <span>{name}</span>
          </div>
        </div>
      </section>

      <section className='scroller conversation flex flex-col' id='scroller' onScroll={onScroll}>
        <div className='whitespace my-3'></div>
        {totalMessages > messages.length ? (
          <div
            className={
              'spinner flex justify-center items-center my-2 scale-0 transform transition-all duration-200 ' +
              (loadingMoreMessages && 'scale-100')
            }
          >
            <RawSpinner width='2em' height='2em' />
          </div>
        ) : (
          <span className='p-3 text-gray-600 text-center mb-3 max-w-sm mx-auto'>
            Este es el inicio de tu conversación con {name}
          </span>
        )}
        {messages.map((message, index: number) => {
          let shouldAddDateTag = false;
          if (index !== 0) {
            const previousMessage = messages[index - 1];
            shouldAddDateTag = !moment(message.createdAt).isSame(moment(previousMessage.createdAt), 'day');
          }
          return (
            <Fragment key={message.id}>
              {shouldAddDateTag && <DateMessage date={message.createdAt} />}
              <Message mine={isFromMyself(message)} message={message} onDeleteMessage={onDeleteMessage} />
            </Fragment>
          );
        })}
        <div
          className={
            'spinner flex justify-center items-center scale-0 transform transition-all duration-200 ' +
            (loadingNewMessages && 'scale-100 my-5')
          }
        >
          <RawSpinner width='2em' height='2em' />
        </div>
        <div className='anchor h-px flex-none'></div>
      </section>

      <section className='input-container flex p-4 pt-0 items-center'>
        <div className='text-area p-2 flex items-center bg-white py-1'>
          <button
            className={
              'rounded-full shadow-md justify-center p-2 items-center absolute g-blue-button bottom-btn bottom-0 right-0 z-50 mr-6 mb-20 transform transition duration-200 ' +
              (inTop ? 'flex' : 'translate-x-32')
            }
            onClick={scrollBottom}
          >
            <ArrowDown width='0.5em' height='0.5em' />
          </button>
          <label htmlFor='attachment_upload'>
            <input
              name='attachment_upload'
              id='attachment_upload'
              accept={'*/*'}
              multiple={true}
              type='file'
              onChange={sendAttachment}
            />
            <div className='cursor-pointer p-1 flex items-center justify-center transition duration-200 transform files-button hover:scale-110'>
              <Clip height='25px' width='25px' />
            </div>
          </label>

          <textarea
            name={'message'}
            className='message-input h-6 resize-none'
            placeholder='Escribe un mensaje...'
            value={inputMessage}
            autoFocus
            ref={messageInputElement}
            onKeyDown={(e): void => {
              if (e.key === 'Enter' && !e.shiftKey) {
                onSend();
              }
            }}
            onChange={({ target: { value: newVal } }): void => {
              const match = /\r|\n/.exec(newVal);
              // If this is true, a newline was inserted as first character
              if (match && match.index === 0) return;
              if (!sendingMessage) {
                onInputMessageChange(newVal);
              }
            }}
          />

          <div
            className='send-button cursor-pointer flex transition duration-200 transform hover:scale-110'
            onClick={(): void => {
              onSend();
              scrollBottom();
              messageInputElement.current?.focus();
            }}
          >
            <Send height='28px' width='28px' />
          </div>
        </div>
      </section>

      <style jsx>{`
        .active-conversation {
          margin: 0px 0px 0px 0px;
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        .return-button {
          padding-left: calc(20px - 0.5rem);
        }

        .notifications {
          border-bottom: black;
        }

        .person {
          display: flex;
          border-top: 2px solid #e8e8e8;
          border-bottom: 2px solid #e8e8e8;
        }

        textarea {
          border: none;
          padding: 0px;
          box-shadow: none;
          width: 100%;
          border-radius: 0;
        }

        textarea:focus {
          box-shadow: none;
        }

        .text-area {
          box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
          transition: box-shadow 0.08s ease-in, color 0.08s ease-in;
          width: 100%;
          border-radius: 2rem;
          padding: 0px 0px;
        }

        .conversation {
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          height: 100%;
          padding-left: 6%;
          padding-right: 6%;
        }

        .input-container {
          top: 0px;
        }

        .hide {
          display: flex;
          flex-direction: column;
        }

        .return-button {
          display: none;
        }

        input[type='file'] {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }

        @media only screen and (max-width: 640px) {
          .return-button {
            display: flex;
          }
          .active-conversation {
            position: fixed;
            left: 0;
            bottom: 0;
            top: 58px;
            right: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default ActiveConversation;
