import { gql } from '@apollo/client';
import { useSwrQuery } from 'components/hooks/swrHooks';
import CSS from 'csstype';
import { FunctionComponent } from 'react';

export const GET_SIGNED_URL = gql`
  query signedUrl($filename: String!) {
    signedUrl(filename: $filename) {
      url
    }
  }
`;

type ProtectedImageProps = {
  size?: '800x480' | '320x200' | '60x60';
  filename: string;
  alt?: string;
  className?: string;
  objectFit?: CSS.ObjectFitProperty;
  imgStyle?: Record<string, unknown>;
  style?: Record<string, unknown>;
};

const ProtectedImage: FunctionComponent<ProtectedImageProps> = ({
  filename,
  alt,
  className,
  objectFit,
  imgStyle,
  size = '320x200'
}) => {
  const { loading, error, data } = useSwrQuery(GET_SIGNED_URL, {
    variables: { filename: `resized/${size}/${filename}` },
    pollInterval: 0
  });
  try {
    if (error) {
      console.error('ProtectedImage:', error);
      return <div className='p-5 bg-gray-600'>Error</div>;
    }

    if (loading || !data || data.signedUrl.url === '') {
      console.log('loading || !data', loading, data);
      return <div className={className + ' w-full h-full'}></div>;
    }

    return (
      <>
        <img
          src={data.signedUrl.url}
          alt={alt || 'Imagen ' + filename}
          className={className}
          style={{ ...imgStyle, objectFit }}
        />
      </>
    );
  } catch (error) {
    console.error('EROR IN ProtectedImage', error);
    return <div className={className + ' w-full h-full bg-gray-600'}>Error</div>;
  }
};

export default ProtectedImage;
