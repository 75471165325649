import Can from 'components/Can';
import LoadingSpinner from 'components/LoadingSpinner';
import UserContext from 'context/UserContext';
import React, { Dispatch, FunctionComponent, SetStateAction, useContext } from 'react';
import Search from '../../../public/icons/buscar.svg';
import LeftArrow from '../../../public/icons/left-arrow.svg';
import { Announcement, Chat, ChatType, Employee, Patient } from '../../generated/schema-types';
import AddChatTab from './AddChatTab';
import AnnouncementPreview from './AnnouncementPreview';
import ConversationPreview from './ConversationPreview';

export interface ConversationsProps {
  chatType: ChatType;
  onChangeChatTyoe: (mode: ChatType) => void;
  filter: string;
  onFilterChange: (text: string) => void;
  onChangeShowNewConversation: (value: boolean) => void;
  showNewConversation: boolean;
  chats: Chat[];
  onChatClick: (chat: Chat) => void;
  onNewConversation: (person: Patient | Employee) => unknown;
  selectedChat: Chat | null;
  lastAnnouncement: Announcement | null;
  loadingAnnouncement: boolean;
  onClickAnnouncements: () => void;
  announcementsMode: boolean;
  reachedBottom: boolean;
  setReachedBottom: Dispatch<SetStateAction<boolean>>;
  loadingChats: boolean;
}

const Conversations: FunctionComponent<ConversationsProps> = ({
  chatType,
  onChangeChatTyoe: onChangeMode,
  lastAnnouncement,
  loadingAnnouncement,
  filter,
  onFilterChange,
  onChangeShowNewConversation,
  showNewConversation,
  chats,
  onChatClick,
  onNewConversation,
  selectedChat,
  onClickAnnouncements,
  announcementsMode,
  reachedBottom,
  setReachedBottom,
  loadingChats
}) => {
  const user = useContext(UserContext);
  return (
    <div className='relative'>
      <Can do='patientChats'>
        <div className='persons flex justify-evenly p-1'>
          <button
            className={'font-light shadow-none ' + (chatType === ChatType.EMPLOYEE_ON_EMPLOYEE && 'text-primary')}
            onClick={() => onChangeMode(ChatType.EMPLOYEE_ON_EMPLOYEE)}
          >
            EMPLEADOS
          </button>
          <button
            className={'font-light shadow-none ' + (chatType === ChatType.BRANCH_ON_PATIENT && 'text-primary')}
            onClick={() => onChangeMode(ChatType.BRANCH_ON_PATIENT)}
          >
            PACIENTES
          </button>
        </div>

        <div className='my-3'>
          <hr />
        </div>
      </Can>

      <div className='tabs flex mb-2'>
        <div
          className={`cursor-pointer items-center flex p-4 ${!showNewConversation && 'hidden'}`}
          onClick={(): void => onChangeShowNewConversation(false)}
        >
          <LeftArrow fill='var(--primary-color)' width='1.7em' height='1.7em' />
        </div>
        <button
          className={`g-blue-button ${showNewConversation && 'hidden '}`}
          onClick={(): void => onChangeShowNewConversation(true)}
        >
          Nueva conversación
        </button>
      </div>
      <div className='search-bar flex items-center'>
        <input
          className='busqueda text-sm flex-1 border-none shadow-none bg-transparent'
          type='text'
          value={filter}
          onChange={(e): unknown => onFilterChange(e.target.value)}
          placeholder='Buscar por nombre...'
        />
        <div className='search-icon bg-white flex items-center justify-center'>
          <Search height='37' width='33' />
        </div>
      </div>
      {loadingChats || loadingAnnouncement ? (
        <LoadingSpinner loading />
      ) : (
        <>
          {!showNewConversation ? (
            <div>
              <AnnouncementPreview
                loading={loadingAnnouncement}
                announcement={lastAnnouncement}
                onClick={onClickAnnouncements}
                announcementsMode={announcementsMode}
              />
              <div className='chats-area'>
                {chats.length === 0 && (
                  <span className='flex items-center justify-center m-4 text-center text-lg text-gray-300'>
                    No se encontraron conversaciones. ¡Inicia una!
                  </span>
                )}
                {chats.map(chat => {
                  let name = '',
                    photo = '';
                  if (chat.other?.employee) {
                    name = chat.other.employee.name;
                    photo = chat.other.employee.photo ?? '';
                  } else {
                    name = chat.other?.patient?.name ?? '';
                    photo = chat.other?.patient?.photo ?? '';
                  }
                  //const { name, photo } = chat.other.employee ?? chat.other.patient;
                  return (
                    <ConversationPreview
                      selected={selectedChat !== null && chat.id === selectedChat.id}
                      key={chat.id}
                      lastMessage={chat.lastMessage ?? undefined}
                      unreadMessages={chat.unreadMessages ?? 0}
                      name={name}
                      photo={photo}
                      lastMessageMine={
                        chat.lastMessage ? chat.lastMessage.from.id === user?.chatParticipant?.id : false
                      }
                      onClick={(): unknown => onChatClick(chat)}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <AddChatTab
              chatType={chatType}
              filter={filter}
              chatParticipantsIds={chats.map(c => c.other?.id ?? '')}
              onClick={onNewConversation}
              reachedBottom={reachedBottom}
              setReachedBottom={setReachedBottom}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Conversations;
