import { gql } from '@apollo/client';
import { useSwrQuery } from 'components/hooks/swrHooks';
import LoadingSpinner from 'components/LoadingSpinner';
import { useNotification } from 'context/NotificationContext';
import { FunctionComponent } from 'react';

const GET_EMPLOYEE_NAMES_QUERY = gql`
  query getEmployeeNames($employeeIds: [String!]!) {
    employees(where: { id: { in: $employeeIds } }) {
      name
    }
  }
`;

export interface AnnouncementViewsProps {
  employeeIds: string[];
}

const AnnouncementViews: FunctionComponent<AnnouncementViewsProps> = ({ employeeIds }) => {
  const { newFeedbackNotification } = useNotification();

  const { loading, data } = useSwrQuery(GET_EMPLOYEE_NAMES_QUERY, {
    variables: { employeeIds },
    onError: () => {
      newFeedbackNotification({
        level: 'error',
        message: 'Hubo un problema al obtener los empleados.'
      });
    }
  });
  return (
    <div className='flex flex-col'>
      {loading ? (
        <LoadingSpinner loading />
      ) : (
        <div className='bg-white rounded-md p-8 flex flex-col space-y-2'>
          <span className='text-lg font-bold'>Quiénes lo han visto</span>
          <ul className='names'>
            {data.employees.length === 0 && <span className='p-6 text-gray-600 text-center'>Nadie lo ve aún</span>}
            {data.employees.map(({ id, name }: { id: string; name: string }) => (
              <li key={id}>{name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AnnouncementViews;
