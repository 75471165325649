import { useMutation, useSubscription, gql } from '@apollo/client';
import LoadingSpinner from '../components/LoadingSpinner';
import Layout from '../components/Layout';
import { cloneDeep } from 'lodash';
import ActiveConversation from '../components/chats/ActiveConversation';
import UserContext from '../context/UserContext';
import { useState, useEffect, useContext, useRef } from 'react';
import ErrorPage from '../components/ErrorPage';
import Conversations from '../components/chats/Conversations';
import {
  ChatMessageCreateInput,
  UploadFilePayload,
  BatchPayload,
  Announcement,
  Employee,
  Patient,
  Chat,
  ChatCreateInput,
  ChatMessage,
  ChatParticipantWhereUniqueInput,
  ChatType
} from '../generated/schema-types';
import isEmpty from 'utils/isEmpty';
import { useNotification } from 'context/NotificationContext';
import { sortByCreatedAtAsc } from 'utils/sortByCreatedAt';
import AnnouncementsConversation from 'components/chats/AnnouncementsConversation';
import { NextPage } from 'next';
import { SupermuelitaIcon } from '../../public/icons';
import { PageProps } from './_app';
import { useSwrQuery, useLazySwrQuery } from 'components/hooks/swrHooks';

const CHAT_FRAGMENT = gql`
  fragment FullChat on Chat {
    id
    createdAt
    type
    participants {
      id
      patient {
        id
        username
        name
      }
      employee {
        id
        username
        name
      }
      branch {
        id
        name
      }
    }
    lastMessage {
      id
      createdAt
      content
      read
      isAttachment
      attachmentMimetype
      attachmentName
      from {
        id
        patient {
          id
          username
          name
        }
        employee {
          id
          username
          name
        }
        branch {
          id
          name
        }
      }
    }
    other {
      id
      patient {
        id
        username
        name
        branch {
          id
          name
        }
      }
      employee {
        id
        username
        name
      }
      branch {
        id
        name
      }
    }
    messages {
      content
      read
    }
    unreadMessages
  }
`;

/**
 * GraphQL operations
 */
export interface UploadFileResult {
  uploadFile: UploadFilePayload;
}
export interface UploadFileVariables {
  file: File;
}
const UPLOAD_FILE_MUTATION = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      id
      path
      mimetype
      encoding
      filename
    }
  }
`;

export interface AddMessageMutationResult {
  createOneChatMessage: ChatMessage;
}
export interface AddMessageMutationVariables {
  message: ChatMessageCreateInput;
}
const ADD_MESSAGE_MUTATION = gql`
  mutation createOneMessage($message: ChatMessageCreateInput!) {
    createOneChatMessage(data: $message) {
      id
      content
      createdAt
      read
      isAttachment
      attachmentMimetype
      attachmentName
      from {
        id
        patient {
          id
          username
          name
        }
        employee {
          id
          username
          name
        }
        branch {
          id
          name
        }
      }
      chat {
        ...FullChat
      }
    }
  }
  ${CHAT_FRAGMENT}
`;

export interface CreateAnnouncementMutationResult {
  createOneAnnouncement: Announcement;
}
export interface CreateAnnouncementMutationVariables {
  userId: string;
  content: string;
}

const CREATE_ANNOUNCEMENT_MUTATION = gql`
  mutation createAnn($userId: String!, $content: String!) {
    createOneAnnouncement(
      data: { uploader: { connect: { id: $userId } }, content: $content, announcementViews: { create: [] } }
    ) {
      id
      updatedAt
      createdAt
      content
      uploaderId
      uploader {
        name
      }
      announcementViews {
        id
        employeeId
      }
    }
  }
`;

export interface SeeAnnouncementMutationResult {
  createOneAnnouncementView: Announcement;
}
export interface SeeAnnouncementMutationVariables {
  employeeId: string;
  announcementId: string;
}
const SEE_ANNOUNCEMENT_MUTATION = gql`
  mutation setAnnouncementSeen($announcementId: String!, $employeeId: String!) {
    updateOneAnnouncement(
      where: { id: $announcementId }
      data: {
        announcementViews: {
          upsert: {
            where: { employeeId_announcementId: { employeeId: $employeeId, announcementId: $announcementId } }
            update: {}
            create: { employee: { connect: { id: $employeeId } } }
          }
        }
      }
    ) {
      id
      createdAt
      content
      announcementViews {
        id
        employeeId
      }
    }
  }
`;

interface GetAnnouncementsQueryResult {
  announcements: Announcement[];
  count: { announcement: number };
}
interface GetAnnouncementsQueryVariables {
  skip: number;
}
const LAST_ANNOUNCEMENT_QUERY = gql`
  query getAnnouncements($skip: Int!) {
    announcements(orderBy: { createdAt: desc }, take: 20, skip: $skip) {
      id
      createdAt
      content
      announcementViews {
        id
        employeeId
      }
    }
    count {
      announcement
    }
  }
`;

export interface GetChatsQueryResult {
  myChats: Chat[];
}
export interface GetChatsQueryVariables {
  filter: string;
  chatType: ChatType;
  skip: number;
  take: number;
}
export const GET_CHATS_QUERY = gql`
  query myChats($filter: String!, $chatType: ChatType!, $skip: Int!, $take: Int!) {
    myChats(chatType: $chatType, skip: $skip, take: $take, searchTerm: $filter) {
      ...FullChat
    }
  }
  ${CHAT_FRAGMENT}
`;
export interface GetMessagesQueryResult {
  chatMessages: ChatMessage[];
  count: { chatMessage: number };
}
export interface GetMessagesQueryVariables {
  chatId: string;
  skip: number;
}
export const GET_MESSAGES_QUERY = gql`
  query getMessages($chatId: String!, $skip: Int!) {
    chatMessages(where: { chat: { id: { equals: $chatId } } }, take: 20, skip: $skip, orderBy: { createdAt: desc }) {
      id
      content
      read
      isAttachment
      attachmentMimetype
      attachmentName
      createdAt
      from {
        id
      }
    }
    count {
      chatMessage(where: { chat: { id: { equals: $chatId } } })
    }
  }
`;

interface CreateNewChatMutationResult {
  createOneChat: Chat;
}
interface CreateNewChatMutationVariables {
  data: ChatCreateInput;
}
const CREATE_NEW_CHAT_MUTATION = gql`
  mutation createChat($data: ChatCreateInput!) {
    createOneChat(data: $data) {
      ...FullChat
    }
  }
  ${CHAT_FRAGMENT}
`;

interface SetMessagesReadMutationResult {
  readMessages: BatchPayload;
}
interface SetMessagesReadMutationVariables {
  chatId: string;
  sender: ChatParticipantWhereUniqueInput;
}
const SET_MESSAGES_AS_READ_MUTATION = gql`
  mutation readMessages($chatId: String!, $sender: ChatParticipantWhereUniqueInput!) {
    readMessages(chat: { id: $chatId }, sender: $sender) {
      count
    }
  }
`;

interface DeleteMessageMutationResult {
  deleteOneChatMessage: ChatMessage;
}
interface DeleteMessageMutationVariables {
  messageId: string;
}
const DELETE_MESSAGE_MUTATION = gql`
  mutation deleteMessage($messageId: String!) {
    deleteOneChatMessage(where: { id: $messageId }) {
      id
      content
    }
  }
`;

interface NewMessageSubscriptionResult {
  onCreateChatMessage: ChatMessage;
}
const NEW_MESSAGE_SUBSCRIPTION = gql`
  subscription onMessage {
    onCreateChatMessage {
      id
      content
      createdAt
      read
      isAttachment
      attachmentMimetype
      attachmentName
      from {
        id
      }
      chat {
        id

        ...FullChat
      }
    }
  }
  ${CHAT_FRAGMENT}
`;

export interface OnMessagesSeenSubscriptionResult {
  onMessagesSeen: Chat;
}
export const ON_MESSAGES_SEEN_SUBSCRIPTION = gql`
  subscription {
    onMessagesSeen {
      id
    }
  }
`;

const Mensajes: NextPage<PageProps> = () => {
  const { newFeedbackNotification } = useNotification();
  /**
   * State variables
   */
  const user = useContext(UserContext) as Employee;
  const [filter, setFilter] = useState('');
  const [chatType, setChatType] = useState<ChatType>(ChatType.EMPLOYEE_ON_EMPLOYEE);
  const [messageText, setMessageText] = useState('');
  const [hideConversations, setHideChats] = useState(false);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [messageOffset] = useState(0);
  const [showNewConversation, setShowNewConversation] = useState(false);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
  const [announcementsMode, setAnnouncementsMode] = useState(false);
  const [announcementInput, setAnnouncementInput] = useState('');
  const [loadingMoreAnnouncements, setLoadingMoreAnnouncements] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(false);

  const fetchAmount = 10;

  /**
   * Refs
   */
  const latestCurrentChat = useRef<Chat | null>(selectedChat);
  const latestMessageAmount = useRef(messageOffset);

  let other: Employee | Patient | null = null;
  if (selectedChat?.other) {
    other = selectedChat.other.employee ?? selectedChat.other.patient ?? null;
    // The sender changes depending on the chat type
  }
  let myParticipant: ChatParticipantWhereUniqueInput;
  if (chatType === ChatType.EMPLOYEE_ON_EMPLOYEE) {
    myParticipant = { employeeId: user.id };
  } else {
    if (selectedChat && selectedChat.type === ChatType.BRANCH_ON_PATIENT) {
      myParticipant = { branchId: (other as Patient)?.branch.id ?? '' };
    }
  }

  /**
   * Apollo hooks
   */
  // QUERIES
  const [
    getMessagesQuery,
    { data: fetchedMessages, called: calledMessages, loading: loadingMessages, fetchMore }
  ] = useLazySwrQuery<GetMessagesQueryResult, GetMessagesQueryVariables>(GET_MESSAGES_QUERY);
  const getChatsVariables: GetChatsQueryVariables = {
    filter,
    chatType,
    skip: 0,
    take: fetchAmount
  };
  const {
    loading: loadingChats,
    error: chatsError,
    data: chatsData,
    refetch: refetchMessages,
    fetchMore: fetchMoreChats
  } = useSwrQuery<GetChatsQueryResult, GetChatsQueryVariables>(GET_CHATS_QUERY, {
    variables: getChatsVariables,
    onCompleted: async data => {
      if (data?.myChats && data.myChats.length > 0) {
        getMessagesQuery({ variables: { chatId: data.myChats[0].id, skip: 0 } });
      }
    }
  });
  const {
    loading: loadingAnnouncement,
    data: announcementData,
    fetchMore: fetchMoreAnnouncements,
    refetch: refetchAnnouncements
  } = useSwrQuery<GetAnnouncementsQueryResult, GetAnnouncementsQueryVariables>(LAST_ANNOUNCEMENT_QUERY, {
    variables: { skip: 0 }
  });

  // SUBSCRIPTIONS
  useSubscription<NewMessageSubscriptionResult>(NEW_MESSAGE_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: ({ subscriptionData, client }) => {
      // Update the chats query cache (with latest message)
      const cachedChats = client.readQuery<GetChatsQueryResult, GetChatsQueryVariables>({
        query: GET_CHATS_QUERY,
        variables: getChatsVariables
      });

      if (cachedChats === null) return;
      const chats = cachedChats.myChats;
      const newChats: Chat[] = cloneDeep(chats);
      if (!subscriptionData.data) {
        return;
      }
      const newMessage: ChatMessage = subscriptionData.data.onCreateChatMessage;
      let chatIndex = newChats.findIndex((c: Chat) => c.id === newMessage.chat.id);
      if (chatIndex === -1) {
        // If it's a new chat, we need to add it
        newChats.push(newMessage.chat);
        chatIndex = newChats.length - 1;
      } else {
        newChats[chatIndex].lastMessage = newMessage;
      }

      try {
        const cachedMessages = client.readQuery<GetMessagesQueryResult>({
          query: GET_MESSAGES_QUERY,
          variables: { chatId: newMessage.chat.id, skip: 0 }
        });

        if (cachedMessages === null) return;
        const cachedMessagesCopy: GetMessagesQueryResult = cloneDeep(cachedMessages);
        cachedMessagesCopy.chatMessages.push(newMessage);

        // Update what matters if the message is from the current chat
        if (latestCurrentChat.current && latestCurrentChat.current.id === newMessage.chat.id) {
          newChats[chatIndex].unreadMessages = 0;
          if (document.visibilityState === 'visible') {
            readMessages(newMessage.chat);
          }
          // Set messages I sent to read
          for (let i = 0; i < cachedMessagesCopy.chatMessages.length; i++) {
            const message = cachedMessagesCopy.chatMessages[i];
            message.read = true; // For instant read recipt
          }
        }

        cachedMessagesCopy.count.chatMessage++;
        client.writeQuery({
          query: GET_MESSAGES_QUERY,
          variables: { chatId: newMessage.chat.id, skip: 0 },
          data: cachedMessagesCopy
        });
      } catch (error) {
        console.log(error);
        // If we had an error it just means these messages are not in the cache
      }

      client.writeQuery<GetChatsQueryResult, GetChatsQueryVariables>({
        query: GET_CHATS_QUERY,
        variables: getChatsVariables,
        data: { myChats: newChats }
      });
    }
  });
  useSubscription<OnMessagesSeenSubscriptionResult>(ON_MESSAGES_SEEN_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: ({ subscriptionData: { data }, client }) => {
      const chatId = data?.onMessagesSeen.id;
      if (!chatId) return;

      try {
        const cachedData = client.readQuery<GetMessagesQueryResult, GetMessagesQueryVariables>({
          query: GET_MESSAGES_QUERY,
          variables: { chatId, skip: 0 }
        });
        if (cachedData === null) return;
        const cachedDataCopy: GetMessagesQueryResult = cloneDeep(cachedData);

        // Set messages from the chat as 'read'
        for (let i = 0; i < cachedDataCopy.chatMessages.length; i++) {
          const message = cachedDataCopy.chatMessages[i];
          message.read = true;
        }
        client.writeQuery<GetMessagesQueryResult, GetMessagesQueryVariables>({
          query: GET_MESSAGES_QUERY,
          variables: { chatId, skip: 0 },
          data: cachedDataCopy
        });
      } catch (error) {
        return;
      }
    }
  });

  // MUTATIONS
  const [addMessageMutation, { loading: sendingMessage }] = useMutation<
    AddMessageMutationResult,
    AddMessageMutationVariables
  >(ADD_MESSAGE_MUTATION);
  const [setMessagesAsReadMutation] = useMutation<SetMessagesReadMutationResult, SetMessagesReadMutationVariables>(
    SET_MESSAGES_AS_READ_MUTATION
  );
  const [createNewChatMutation] = useMutation<CreateNewChatMutationResult, CreateNewChatMutationVariables>(
    CREATE_NEW_CHAT_MUTATION
  );
  const [deleteMessageMutation] = useMutation<DeleteMessageMutationResult, DeleteMessageMutationVariables>(
    DELETE_MESSAGE_MUTATION
  );
  const [uploadPhotoMutation, { loading: loadingFile }] = useMutation<UploadFileResult, UploadFileVariables>(
    UPLOAD_FILE_MUTATION
  );
  const [createAnnouncement, { loading: loadingCreateAnnouncement }] = useMutation<
    CreateAnnouncementMutationResult,
    CreateAnnouncementMutationVariables
  >(CREATE_ANNOUNCEMENT_MUTATION);
  const [updateAnnouncement] = useMutation<SeeAnnouncementMutationResult, SeeAnnouncementMutationVariables>(
    SEE_ANNOUNCEMENT_MUTATION
  );

  /**
   * Effects
   */
  // This effect keeps the refs' current value synchronized
  useEffect(() => {
    latestCurrentChat.current = selectedChat;
    latestMessageAmount.current = messageOffset;
  }, [selectedChat, messageOffset]);

  useEffect(() => {
    if (!loadingChats && chatsData) {
      const chatsCopy: Chat[] = chatsData.myChats;
      const totalUnread = chatsCopy.reduce((acc, r) => {
        if (!r.unreadMessages) {
          return acc;
        }
        return acc + r.unreadMessages;
      }, 0);
      if (totalUnread === 0) {
        document.title = `Mensajes | Ayzer Dental`;
      } else {
        document.title = `🔔 ${totalUnread} Mensajes no leídos`;
      }
    }
  }, [chatsData, loadingChats]);

  useEffect(() => {
    document.addEventListener('visibilitychange', function () {
      if (latestCurrentChat.current !== null && document.visibilityState === 'visible') {
        readMessages(latestCurrentChat.current);
      }
    });
  }, []);
  useEffect(() => {
    refetchMessages();
    refetchAnnouncements();
  }, []);
  /**
   * Event handlers
   */
  const sendMessage = async (): Promise<void> => {
    if (isEmpty(messageText)) {
      return;
    }
    const messageInput = messageText;
    setMessageText('');
    const from = myParticipant;
    const message: ChatMessageCreateInput = {
      content: messageText.trim(),
      read: false,
      from: {
        connect: from
      },
      chat: {
        connect: {
          id: selectedChat?.id
        }
      }
    };

    try {
      await createMessage(message);
    } catch (error) {
      setMessageText(messageInput);
    }
  };

  const sendAttachment = async (file: File): Promise<void> => {
    try {
      const { data } = await uploadPhotoMutation({ variables: { file } });
      const from = myParticipant;
      if (!data || !data.uploadFile.path) {
        console.error('No data or uploadfile.path');
        return;
      }
      const message: ChatMessageCreateInput = {
        content: data.uploadFile.path,
        isAttachment: true,
        attachmentMimetype: data?.uploadFile.mimetype,
        attachmentName: data?.uploadFile.filename,
        read: false,
        from: {
          connect: from
        },
        chat: {
          connect: {
            id: selectedChat?.id
          }
        }
      };
      createMessage(message);
    } catch (error) {
      console.log(error);
    }
  };

  const createMessage = async (message: ChatMessageCreateInput): Promise<void> => {
    // const optimisticResponseDate = new Date().toISOString();
    // const optimisticResponse = {
    //   createOneEmployeeChatMessage: {
    //     id: optimisticResponseDate,
    //     content: message.content,
    //     createdAt: optimisticResponseDate,
    //     read: false,
    //     isAttachment: !!message.isAttachment,
    //     attachmentMimetype: message.attachmentMimetype || null,
    //     attachmentName: message.attachmentName || null,
    //     __typename: 'ChatMessage',
    //     from: {
    //       id: message.from.connect.id,
    //       __typename: 'Employee'
    //     },
    //     chat: {
    //       id: message.chat.connect.id,
    //       __typename: 'Chat',
    //       employee1: {
    //         id: selectedChat.employee1.id,
    //         name: selectedChat.employee1.name,
    //         photo: selectedChat.employee1.photo,
    //         __typename: 'Employee'
    //       },
    //       employee2: {
    //         id: selectedChat.employee2.id,
    //         name: selectedChat.employee2.name,
    //         photo: selectedChat.employee2.photo,
    //         __typename: 'Employee'
    //       },
    //       lastMessage: {
    //         id: '123',
    //         content: message.content,
    //         createdAt: optimisticResponseDate,
    //         read: false,
    //         isAttachment: message.isAttachment,
    //         attachmentMimetype: message.isAttachment,
    //         attachmentName: message.attachmentName,
    //         __typename: 'ChatMessage',
    //         from: {
    //           id: message.from.connect.id,
    //           __typename: 'Employee'
    //         }
    //       }
    //     }
    //   }
    // } as never;
    try {
      await addMessageMutation({
        variables: { message },
        update: (cache, result) => {
          if (!result.data) {
            return;
          }
          if (!selectedChat) {
            return;
          }
          const cachedData = cache.readQuery<GetMessagesQueryResult, GetMessagesQueryVariables>({
            query: GET_MESSAGES_QUERY,
            variables: { chatId: selectedChat.id, skip: 0 }
          });
          if (!cachedData) {
            console.error('No cachedData GET_MESSAGES');
            return;
          }
          const cachedDataCopy: GetMessagesQueryResult = cloneDeep(cachedData);
          cachedDataCopy.chatMessages.push(result.data.createOneChatMessage);
          cachedDataCopy.count.chatMessage++;
          cache.writeQuery({
            query: GET_MESSAGES_QUERY,
            variables: { chatId: selectedChat?.id, skip: 0 },
            data: cachedDataCopy
          });
        }
      });
    } catch (error) {
      newFeedbackNotification({
        level: 'warning',
        message: 'No se envío tu mensaje. Inténtalo de nuevo.',
        title: 'Uh oh'
      });
      console.log(error);
    }
  };

  const updateChatList = (text: string): void => {
    setFilter(text);
  };

  const onClickChat = (chat: Chat): void => {
    setHideChats(true);
    setSelectedChat(chat);
    setAnnouncementsMode(false);
    getMessagesQuery({ variables: { chatId: chat.id, skip: 0 } });
    readMessages(chat);
  };

  const onClickAnnouncements = (): void => {
    setHideChats(true);
    setSelectedChat(null);
    setAnnouncementsMode(true);
    announcementData?.announcements.forEach(announcement => {
      if (!announcement.announcementViews.some(ann => ann.employeeId === user.id)) {
        updateAnnouncement({ variables: { employeeId: user.id, announcementId: announcement.id } });
      }
    });
  };

  const readMessages = (selected: Chat): void => {
    setMessagesAsReadMutation({
      variables: {
        chatId: selected.id,
        sender:
          chatType === ChatType.EMPLOYEE_ON_EMPLOYEE
            ? { employeeId: selected.other?.employee?.id }
            : { patientId: selected.other?.patient?.id }
      },
      update: cache => {
        const cacheData = cache.readQuery<GetChatsQueryResult, GetChatsQueryVariables>({
          query: GET_CHATS_QUERY,
          variables: getChatsVariables
        });
        if (!cacheData) {
          return;
        }
        const { myChats } = cacheData;
        const chatsCopy: Chat[] = cloneDeep(myChats);
        const chat = chatsCopy.find(chat => chat.id === selected.id);
        if (!chat) {
          console.error('No chat');
          return;
        }
        chat.unreadMessages = 0;
        cache.writeQuery<GetChatsQueryResult, GetChatsQueryVariables>({
          query: GET_CHATS_QUERY,
          variables: getChatsVariables,
          data: { myChats: chatsCopy }
        });
      }
    });
  };

  const getMoreAnnouncements = async (): Promise<void> => {
    try {
      setLoadingMoreAnnouncements(true);
      if (!announcementData) {
        console.error('No annaucementData');
        return;
      }
      await fetchMoreAnnouncements({
        variables: { skip: announcementData.announcements.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            announcements: [...prev.announcements, ...fetchMoreResult.announcements]
          });
        }
      });
      setLoadingMoreAnnouncements(false);
      announcementData.announcements.forEach(announcement => {
        if (!announcement.announcementViews.some(ann => ann.employeeId === user.id)) {
          updateAnnouncement({ variables: { employeeId: user.id, announcementId: announcement.id } });
        }
      });
    } catch (error) {
      newFeedbackNotification({
        level: 'error',
        message: 'Ocurrió un error al cargar más mensajes'
      });
    }
  };

  const fetchMoreMessages = async (): Promise<void> => {
    try {
      setLoadingMoreMessages(true);
      fetchMore &&
        (await fetchMore({
          variables: { chatId: selectedChat?.id, skip: fetchedMessages?.chatMessages.length },
          updateQuery: (prev: GetMessagesQueryResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return Object.assign({}, prev, {
              chatMessages: [...prev.chatMessages, ...fetchMoreResult.chatMessages]
            });
          }
        }));
      setLoadingMoreMessages(false);
    } catch (error) {
      newFeedbackNotification({
        level: 'error',
        message: 'Ocurrió un error al cargar más mensajes'
      });
    }
  };

  const newAnnouncement = (): void => {
    if (announcementInput === '') {
      return;
    }
    createAnnouncement({
      variables: { userId: user.id, content: announcementInput }
    });
    setAnnouncementInput('');
  };

  const addChat = async (other: Employee | Patient): Promise<void> => {
    try {
      let participants: ChatParticipantWhereUniqueInput[];
      let me = myParticipant;
      // The participants change depending on the chat type
      if (chatType === ChatType.EMPLOYEE_ON_EMPLOYEE) {
        participants = [me, { employeeId: other.id }];
      } else {
        me = { branchId: (other as Patient)?.branch.id ?? '' };
        participants = [me, { patientId: other.id }];
      }
      await createNewChatMutation({
        variables: {
          data: {
            participants: { connect: participants },
            type: chatType
          }
        },
        update: (cache, result) => {
          const cacheData = cache.readQuery<GetChatsQueryResult, GetChatsQueryVariables>({
            query: GET_CHATS_QUERY,
            variables: getChatsVariables
          });
          if (!cacheData) {
            return;
          }
          const { myChats } = cacheData;
          if (!result.data) {
            return;
          }
          cache.writeQuery<GetChatsQueryResult, GetChatsQueryVariables>({
            query: GET_CHATS_QUERY,
            variables: getChatsVariables,
            data: { myChats: myChats.concat([result.data.createOneChat]) }
          });
          if (!result.data) {
            return;
          }
          onClickChat(result.data.createOneChat);
        }
      });
      setShowNewConversation(false);
    } catch (e) {
      console.log(e);

      newFeedbackNotification({
        level: 'error',
        message: 'No se pudo crear la conversación, inténtalo de nuevo'
      });
    }
  };

  const deleteMessage = async (idToDelete: string): Promise<void> => {
    try {
      await deleteMessageMutation({
        variables: { messageId: idToDelete },
        update: cache => {
          // Update the cache accordingly, decreasing the count
          const cachedData = cache.readQuery<GetMessagesQueryResult>({
            query: GET_MESSAGES_QUERY,
            variables: { chatId: latestCurrentChat.current?.id, skip: 0 }
          });
          if (!cachedData) {
            console.error('No cachedData GET_MESSAGES');
            return;
          }
          const cachedDataCopy: GetMessagesQueryResult = cloneDeep(cachedData);
          const i = cachedDataCopy.chatMessages.findIndex(msg => msg.id === idToDelete);
          cachedDataCopy.chatMessages.splice(i, 1);
          cachedDataCopy.count.chatMessage--;
          cache.writeQuery({
            query: GET_MESSAGES_QUERY,
            variables: { chatId: latestCurrentChat.current?.id, skip: 0 },
            data: cachedDataCopy
          });
          const cachedChatsData = cache.readQuery<GetChatsQueryResult>({
            query: GET_CHATS_QUERY,
            variables: getChatsVariables
          });
          if (!cachedChatsData) {
            console.error('No cachedChatsData');
            return;
          }

          const cachedChatsDataCopy: GetChatsQueryResult = cloneDeep(cachedChatsData);
          const j = cachedChatsDataCopy.myChats.findIndex(c => {
            if (!latestCurrentChat?.current) {
              console.error('Not latestCurrentChat');
              return false;
            }
            return c.id === latestCurrentChat.current.id;
          });
          if (cachedChatsDataCopy.myChats[j].lastMessage?.id === idToDelete) {
            const messageIndex = cachedChatsDataCopy.myChats[j].messages.findIndex(m => m.id === idToDelete);
            cachedChatsDataCopy.myChats[j].messages.splice(messageIndex, 1);
            cachedChatsDataCopy.myChats[j].lastMessage =
              cachedChatsDataCopy.myChats[j].messages[cachedChatsDataCopy.myChats[j].messages.length - 1];
          }
          cache.writeQuery({
            query: GET_CHATS_QUERY,
            variables: getChatsVariables,
            data: cachedChatsDataCopy
          });
        }
      });
    } catch (e) {
      newFeedbackNotification({
        level: 'error',
        message: 'Ocurrió un error al borrar el mensaje'
      });
    }
  };

  /**
   * Pre-render calculations
   */
  if (chatsError) {
    console.log(chatsError);
    return <ErrorPage error={chatsError} />;
  }

  let sortedChats: Chat[] = [];
  if (!loadingChats && chatsData?.myChats) {
    sortedChats = cloneDeep(chatsData.myChats);
    sortedChats.sort((chat1, chat2) => {
      const date1 = !chat1.lastMessage ? chat1.createdAt : chat1.lastMessage.createdAt;
      const date2 = !chat2.lastMessage ? chat2.createdAt : chat2.lastMessage.createdAt;
      return new Date(date2).getTime() - new Date(date1).getTime();
    });
    sortedChats = sortedChats.filter(chat => {
      const name = chat.other?.employee?.name ?? chat.other?.patient?.name;
      return name?.toLowerCase().includes(filter.toLowerCase());
    });
  }

  let messages: ChatMessage[] = [];
  if (!loadingMessages && calledMessages && fetchedMessages?.chatMessages) {
    messages = cloneDeep(fetchedMessages.chatMessages);
    messages = sortByCreatedAtAsc(messages);
  }

  let sortedAnnouncements: Announcement[] = [];
  if (announcementData) {
    sortedAnnouncements = cloneDeep(announcementData.announcements);
    sortedAnnouncements.sort((a, b) => {
      const date1 = a === null ? '' : a.createdAt;
      const date2 = b === null ? '' : b.createdAt;

      return new Date(date1).getTime() - new Date(date2).getTime();
    });
  }

  return (
    <Layout currentSection='/mensajes' title='Mensajes' mainPadding='0px 0px 0px 0px'>
      <div className='chat-container'>
        <div
          className={hideConversations ? 'chats-list hide z-10 flex-none' : 'chats-list z-10 flex-none'}
          onScroll={(e: React.BaseSyntheticEvent) => {
            if (e.target.scrollTop > e.target.scrollHeight - e.target.offsetHeight - 50) {
              setReachedBottom(true);
              if (chatType === ChatType.EMPLOYEE_ON_EMPLOYEE) {
                fetchMoreChats({
                  variables: {
                    myselfIds:
                      chatType === ChatType.EMPLOYEE_ON_EMPLOYEE
                        ? [user.chatParticipant?.id]
                        : user.branches.map(b => b.chatParticipant?.id),
                    chatType,
                    skip: chatsData?.myChats.length,
                    take: fetchAmount
                  },
                  updateQuery: (prev: GetChatsQueryResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, {
                      myChats: [...prev.myChats, ...fetchMoreResult.myChats]
                    });
                  }
                });
              } else if (chatType === ChatType.BRANCH_ON_PATIENT) {
                fetchMoreChats({
                  variables: {
                    myselfIds: user.branches.map(b => b.chatParticipant?.id),
                    chatType,
                    skip: chatsData?.myChats.length,
                    take: fetchAmount
                  },
                  updateQuery: (prev: GetChatsQueryResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, {
                      myChats: [...prev.myChats, ...fetchMoreResult.myChats]
                    });
                  }
                });
              }
            } else {
              setReachedBottom(false);
            }
          }}
        >
          {
            <Conversations
              chatType={chatType}
              reachedBottom={reachedBottom}
              setReachedBottom={setReachedBottom}
              onChangeChatTyoe={type => {
                setChatType(type);
                setSelectedChat(null);
              }}
              lastAnnouncement={announcementData?.announcements[0] ?? null}
              loadingAnnouncement={loadingAnnouncement}
              selectedChat={selectedChat ?? null}
              onChangeShowNewConversation={setShowNewConversation}
              showNewConversation={showNewConversation}
              filter={filter}
              onFilterChange={updateChatList}
              chats={sortedChats}
              onChatClick={onClickChat}
              onNewConversation={addChat}
              onClickAnnouncements={onClickAnnouncements}
              announcementsMode={announcementsMode}
              loadingChats={loadingChats}
            />
          }
        </div>

        {selectedChat === null && !announcementsMode ? (
          <div className='p-20 text-gray-600 text-lg flex justify-center flex-1 flex-col items-center'>
            <SupermuelitaIcon height='9rem' width='9rem' />
            <span>Selecciona o crea una conversación para comenzar.</span>
          </div>
        ) : (
          <>
            {loadingMessages || loadingAnnouncement ? (
              <div className='flex justify-center flex-1'>
                <LoadingSpinner loading />
              </div>
            ) : (
              <>
                {!announcementsMode ? (
                  <ActiveConversation
                    messages={messages}
                    inputMessage={messageText}
                    loadingMoreMessages={loadingMoreMessages}
                    loadingNewMessages={loadingFile}
                    onInputMessageChange={setMessageText}
                    onSend={sendMessage}
                    onSendAttachment={sendAttachment}
                    onBack={(): void => {
                      setHideChats(false);
                      setSelectedChat(null);
                    }}
                    totalMessages={fetchedMessages ? fetchedMessages.count.chatMessage : 0}
                    hideConversations={hideConversations}
                    name={other ? other.name : ''}
                    photo={other?.photo ?? null}
                    onScrollTop={fetchMoreMessages}
                    onDeleteMessage={deleteMessage}
                    sendingMessage={sendingMessage}
                    isFromMyself={msg =>
                      msg.from.id === user.chatParticipant?.id ||
                      user.branches.some(b => b.chatParticipant?.id === msg.from.id)
                    }
                  />
                ) : (
                  <AnnouncementsConversation
                    announcements={sortedAnnouncements}
                    loadingMoreMessages={loadingMoreAnnouncements}
                    onBack={(): void => {
                      setHideChats(false);
                      setSelectedChat(null);
                      setAnnouncementsMode(false);
                    }}
                    totalAnnouncements={announcementData ? announcementData.count.announcement : 0}
                    hideConversations={hideConversations}
                    onScrollTop={getMoreAnnouncements}
                    loadingCreateAnnouncement={loadingCreateAnnouncement}
                    inputMessage={announcementInput}
                    setInputMessage={setAnnouncementInput}
                    onSend={newAnnouncement}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
      <style jsx>{`
        .chat-container {
          display: flex;
          position: fixed;
          top: 0;
          right: 0;
          left: 72px;
          bottom: 0;
        }

        .chats-list {
          padding: 20px;
          background-color: #fdfdfd;
          border-radius: 0px 0px 35px 0px;
          justify-content: center;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          width: 350px;
          justify-content: flex-start;
          border-right: 2px solid #e4e4e4;
          border-bottom: 2px solid #e4e4e4;
        }

        .hide {
          display: flex;
        }

        @media only screen and (max-width: 640px) {
          .chat-container {
            top: 58px;
            left: 0;
          }

          .chats-list {
            top: 58px;
            position: fixed;
            left: 0px;
            bottom: 0;
            width: 100vw;
          }

          .hide {
            display: none;
          }
        }
      `}</style>
    </Layout>
  );
};

Mensajes.getInitialProps = (): PageProps => {
  return {
    permissions: ['chats']
  };
};

export default Mensajes;
