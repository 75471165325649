import LoadingSpinner from 'components/LoadingSpinner';
import UserContext from 'context/UserContext';
import { Announcement } from 'generated/schema-types';
import { FunctionComponent, useContext } from 'react';
import { hour, numericDateFormat } from 'utils/dateFormats';
import AyzerLogo from '../../../public/icons/logo.svg';

export interface AnnouncementPreviewProps {
  announcement: Announcement | null;
  loading: boolean;
  onClick: () => void;
  announcementsMode: boolean;
}

const AnnouncementPreview: FunctionComponent<AnnouncementPreviewProps> = ({
  announcement,
  loading,
  onClick,
  announcementsMode
}) => {
  const user = useContext(UserContext);

  return (
    <div className={`wrapper cursor-pointer ${announcementsMode ? `selected` : ``} `} onClick={(): void => onClick()}>
      <div className='responsive-button flex items-center flex-1'>
        <div className='left-content flex'>
          <div className='pic flex items-center'>
            <div className='h-12 w-12 flex bg-white rounded-full'>
              <AyzerLogo height='3rem' width='3rem' />
            </div>
          </div>
          <div className='info flex flex-col ml-6'>
            <span className='contact-name text-base add-ellipsis'>INFORMES AYZER</span>
            {loading ? (
              <LoadingSpinner loading={loading} />
            ) : (
              <span className='add-ellipsis text-sm text-gray-900'>
                {announcement !== null ? (
                  <>
                    <div className='flex items-center space-x-1'>
                      <span className='' style={{ maxHeight: '1.4rem' }}>
                        {announcement.content}
                      </span>
                    </div>
                  </>
                ) : (
                  <span className='text-gray-700'>No hay informes</span>
                )}
              </span>
            )}
            <span className='date text-sm text-gray-900'>
              {announcement !== null &&
                numericDateFormat(new Date(announcement.createdAt)) + ' ' + hour(new Date(announcement.createdAt))}
              <br />
              {announcement !== null && (
                <span className='text-xs text-gray-700'>
                  {announcement.announcementViews.some((ann): boolean => ann.employeeId === user?.id) &&
                    `Visto por ti ${
                      announcement.announcementViews.length - 1 > 0
                        ? `y ${announcement.announcementViews.length - 1} mas`
                        : ``
                    }`}
                </span>
              )}
            </span>
          </div>
        </div>
        {announcement && !announcement.announcementViews.some((ann): boolean => ann.employeeId === user?.id) && (
          <div className='bg-primary rounded-full w-3 h-3 ml-auto flex-none'></div>
        )}
      </div>
      <style jsx>{`
        .wrapper {
          border-radius: 15px;
          background-color: #a4e5ed;
          box-shadow: 1px 1px 10px 1px #edf1f2;
          padding: 15px;
          display: flex;
          margin: 10px auto 10px auto;
          flex: none;
          height: 120px;
        }

        .selected-chat {
          box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
            0 0 0 4px rgba(50, 151, 211, 0.3);
        }

        .pic {
          flex: none;
        }

        .image {
          height: 60px;
          width: 60px;
          border-radius: 30px;
        }

        .add-ellipsis {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 1.8em;
          word-break: break-word;
        }

        .date {
          word-break: break-word;
        }

        .selected {
          box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
            0 0 0 4px rgba(50, 151, 211, 0.3);
        }

        @media only screen and (min-width: 640px) {
          .responsive-button {
          }
        }
      `}</style>
    </div>
  );
};

export default AnnouncementPreview;
