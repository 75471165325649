import ProtectedImage from 'components/ProtectedImage';
import { ChatMessage } from 'generated/schema-types';
import moment from 'moment';
import { FunctionComponent, useContext } from 'react';
import { GestionIcon } from '../../../public/icons';
import ReadIcon from '../../../public/icons/visto.svg';
import { ModalContext } from '../../context/ModalContext';
import { hour, numericDateFormat } from '../../utils/dateFormats';
import AttachmentView from './AttachmentView';
import MessageOptions from './MessageOptions';

const Message: FunctionComponent<{
  mine: boolean;
  message: ChatMessage;
  onDeleteMessage: (id: string) => Promise<void>;
}> = ({ message, onDeleteMessage, mine }) => {
  const sentToday = moment(message.createdAt).isSame(moment(), 'day');
  const sentYesterday = moment(message.createdAt).isSame(moment().add(-1, 'days'), 'day');
  const showDialog = useContext(ModalContext);

  const openMessageOptions = (): void => {
    showDialog(
      <MessageOptions
        message={message}
        onDeleteMessage={(id): void => {
          onDeleteMessage(id);
          showDialog(null);
        }}
      />
    );
  };

  let dateTag: string;
  if (sentToday) {
    dateTag = hour(new Date(message.createdAt));
  } else if (sentYesterday) {
    dateTag = 'Ayer' + ', ' + hour(new Date(message.createdAt)).toString();
  } else {
    dateTag =
      numericDateFormat(new Date(message.createdAt)).toString() + ', ' + hour(new Date(message.createdAt)).toString();
  }

  const isImage = message.attachmentMimetype && message.attachmentMimetype.startsWith('image/');
  return (
    <>
      <div className={'message flex flex-col mb-1 ' + (mine ? 'mine' : 'other')}>
        {message.isAttachment ? (
          <div
            className={'cursor-pointer flex ' + (isImage ? 'img' : 'file')}
            onClick={(): void =>
              showDialog(
                <AttachmentView
                  mimetype={message.attachmentMimetype ?? ''}
                  mine={mine}
                  name={message.attachmentName ?? ''}
                  filename={message.content}
                  onDelete={(): void => {
                    onDeleteMessage(message.id);
                    showDialog(null);
                  }}
                />
              )
            }
          >
            {isImage ? (
              <ProtectedImage
                imgStyle={{ maxWidth: '100%' }}
                className='rounded-lg'
                filename={message.content}
                objectFit='cover'
                size='800x480'
              />
            ) : (
              <div className='file overflow-auto'>
                <div className='thumbnail p-2 w-full flex justify-center items-center text-gray-700'>
                  <div className='file-icon flex-none'>
                    <GestionIcon className='text-gray-600' width='3em' height='3em' />
                  </div>
                  <span className='ml-2 text-sm max-w-xs text-gray-600 overflow-auto'>
                    {message.attachmentName || 'Archivo sin nombre'}
                  </span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <pre
            onClick={(): void => {
              mine && openMessageOptions();
            }}
            style={{ wordBreak: 'break-word' }}
            className={`text-gray-600 break-words text-sm ${mine ? 'cursor-pointer' : 'cursor-default'}`}
          >
            {message.content}
          </pre>
        )}
        <div className='justify-right text-right'>
          <span
            className={'text-gray-500 text-xs flex items-center ml-auto ' + (mine ? 'justify-end' : 'justify-start')}
          >
            <small>{dateTag}</small>
            {mine && <ReadIcon fill={message.read ? '#63bf50' : '#919191'} height='1.4em' width='1.4em' />}
          </span>
        </div>
      </div>
      <style jsx>{`
        .message {
          position: relative;
          word-wrap: break-word;
          max-width: 80%;
          align-self: flex-end;
          padding: 10px;
          flex: none;
        }

        .message:before {
          content: '';
          position: absolute;
          top: 100%;
          width: 0;
          height: 0;
          border: 15px solid transparent;
          border-bottom: 0;
          margin-top: -15px;
        }

        .message:after {
          content: '';
          position: absolute;
          top: 100%;
          width: 15px;
          height: 15px;
          background: #f6f6f6;
          border-bottom: 0;
          margin-top: -15px;
        }

        .mine {
          background: #cce9ee;
          border-radius: 0.4em 0.4em 0em 0.4em;
          align-self: flex-end;
        }

        .mine:before {
          right: 0;
          border-left-color: #cce9ee;
          border-right: 0;
          margin-right: -14.5px;
        }

        .mine:after {
          right: 0;
          border-right: 0;
          margin-right: -15px;
          border-radius: 0px 0px 0px 25px;
        }

        .other {
          background: #dadadb;
          border-radius: 0.4em 0.4em 0.4em 0em;
          align-self: flex-start;
        }

        .other:before {
          left: 0;
          border-right-color: #dadadb;
          border-left: 0;
          margin-left: -14.5px;
        }

        .other:after {
          left: 0;
          border-left: 0;
          margin-left: -15px;
          border-radius: 0px 0px 25px 0px;
        }

        .img {
          max-height: 500px;
          max-width: 600px;
          min-height: 300px;
          min-width: 300px;
        }

        @media only screen and (max-width: 950px) {
          .img {
            min-height: 200px;
            min-width: 100px;
          }
        }
      `}</style>
    </>
  );
};

export default Message;
