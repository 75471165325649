import { ChatMessage } from 'generated/schema-types';
import { FunctionComponent } from 'react';
import { UserIcon } from '../../../public/icons';
import ReadIcon from '../../../public/icons/visto.svg';
import { hour, numericDateFormat } from '../../utils/dateFormats';
import ProtectedImage from '../ProtectedImage';

export interface ConversationPreviewProps {
  photo: string;
  name: string;
  lastMessage?: ChatMessage;
  lastMessageMine: boolean;
  unreadMessages: number;
  selected: boolean;
  onClick: (e?: unknown) => unknown;
}

const ConversationPreview: FunctionComponent<ConversationPreviewProps> = ({
  lastMessage,
  lastMessageMine,
  onClick,
  selected,
  photo,
  name,
  unreadMessages
}) => {
  return (
    <>
      <div className='responsive-button' onClick={onClick}>
        <div
          className={`wrapper flex items-center justify-between cursor-pointer transform transition-shadow duration-200 ${
            selected ? 'selected-chat' : 'hover:bg-gray-200'
          }`}
        >
          <div className='left-content flex'>
            <div className='pic flex items-center'>
              {photo ? (
                <div className='h-12 w-12 flex'>
                  <ProtectedImage
                    className='rounded-full flex w-16'
                    filename={photo}
                    imgStyle={{ width: '100%', height: '100%' }}
                    objectFit='cover'
                    size='60x60'
                  />
                </div>
              ) : (
                <UserIcon height='3rem' width='3rem' fill='rgb(150,150,150)' />
              )}
            </div>
            <div className='info flex flex-col ml-6'>
              <span className='contact-name text-base add-ellipsis'>{name}</span>
              <span className='add-ellipsis text-sm text-gray-600'>
                {lastMessage !== null ? (
                  <>
                    {lastMessage?.isAttachment ? (
                      <span className='text-gray-400'>Adjunto enviado</span>
                    ) : (
                      <div className='flex items-center space-x-1'>
                        <div className='icon flex justify-center items-center flex-none'>
                          {lastMessageMine && <ReadIcon fill={lastMessage?.read ? '#63bf50' : '#919191'} />}
                        </div>
                        <span style={{ maxHeight: '1.4rem' }}>{lastMessage?.content}</span>
                      </div>
                    )}
                  </>
                ) : (
                  <span className='text-gray-400'>Envía un mensaje</span>
                )}
              </span>
              <span className='date text-sm text-gray-500'>
                {lastMessage !== null &&
                  numericDateFormat(new Date(lastMessage?.createdAt ?? '')) +
                    ' ' +
                    hour(new Date(lastMessage?.createdAt ?? ''))}
              </span>
              <span className='unread-messages'></span>
            </div>
          </div>
          <div className='pending-messages m-1 flex items-center'>
            {lastMessage !== null && unreadMessages > 0 && (
              <span className='rounded-full text-xs flex items-center bg-primary text-white h-6 w-6 justify-center'>
                {unreadMessages}
              </span>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        .wrapper {
          border-radius: 15px;
          background-color: #fff;
          box-shadow: 1px 1px 10px 1px #edf1f2;
          padding: 15px;
          display: flex;
          margin: 10px auto 10px auto;
          flex: none;
          height: 100px;
        }

        .selected-chat {
          box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
            0 0 0 4px rgba(50, 151, 211, 0.3);
        }

        .pic {
          flex: none;
        }

        .image {
          height: 60px;
          width: 60px;
          border-radius: 30px;
        }

        .add-ellipsis {
          flex: none;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 1.8em;
          word-break: break-word;
        }

        .date {
          word-break: break-word;
        }

        @media only screen and (min-width: 640px) {
          .responsive-button {
          }
        }
      `}</style>
    </>
  );
};

export default ConversationPreview;
