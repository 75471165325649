import { FunctionComponent } from 'react';
import { mexicanDateFormat } from '../../utils/dateFormats';

const DateMessage: FunctionComponent<{ date: string }> = ({ date }) => {
  return (
    <>
      <div className='wrapper flex flex-col mx-auto'>
        <span>{mexicanDateFormat(new Date(date)).toUpperCase()}</span>
      </div>
      <style jsx>{`
        .wrapper {
          background: #fae9d4;
          border-radius: 0.4em 0.4em 0.4em 0.4em;
          align-self: center;
          padding: 5px 15px 5px 15px;
          flex: none;
          margin-bottom: 15px;
          margin-top: 10px;
        }
      `}</style>
    </>
  );
};

export default DateMessage;
