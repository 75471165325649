import Link from 'next/link';
import { FunctionComponent } from 'react';
import { BarsRegularIcon, LogoIcon } from '../../public/icons';
import NotificationBell from './NotificationBell';

interface TopBarProps {
  onOpenMenu: (value: boolean) => void;
  showNotifications: boolean;
  onChangeShowNotifications: (value: boolean) => void;
  newNotifications: number;
}

const TopBar: FunctionComponent<TopBarProps> = ({
  onOpenMenu,
  onChangeShowNotifications,
  newNotifications,
  showNotifications
}) => {
  return (
    <>
      <div className='top-bar g-container'>
        <span className='burger-button mobile-only'>
          <BarsRegularIcon onClick={(): unknown => onOpenMenu(true)} width='30' height='30' />
        </span>

        <Link href='/'>
          <a className='mobile-only'>
            <span>
              <LogoIcon width='40' height='40' />
            </span>
          </a>
        </Link>

        <NotificationBell
          newNotifications={newNotifications}
          onChangeShowList={onChangeShowNotifications}
          showList={showNotifications}
        />
      </div>

      <style jsx>{`
        .top-bar {
          z-index: 1;
          top: 0;
          height: 58px;
          justify-content: space-between;
          padding: 7px 15px;
          width: 100%;
          position: fixed;
          background-color: #fff;
          border-bottom: 1px solid #e4e4e4;
        }

        .top-bar span {
          line-height: 0;
        }

        .burger-button {
          color: #5d5858;
          padding: 5px;
          cursor: pointer;
        }

        @media screen and (min-width: 640px) {
          .top-bar {
            justify-content: flex-end;
            position: relative;
            display: none;
            width: 100vw;
          }
          .mobile-only {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default TopBar;
