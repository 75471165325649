import { FunctionComponent, useEffect, useRef, useState, Fragment } from 'react';
import Send from '../../../public/icons/send.svg';
import LeftArrow from '../../../public/icons/left-arrow.svg';
import ArrowDown from '../../../public/icons/arrow-down.svg';
import { Announcement } from '../../generated/schema-types';
import RawSpinner from 'components/RawSpinner';
import AyzerLogo from '../../../public/icons/logo.svg';
import AnnouncementMessage from './AnnouncementMessage';
import Can from 'components/Can';
import moment from 'moment';
import DateMessage from './DateMessage';
import useConfirmationDialog from 'components/hooks/useConfirmationDialog';

function scrollBottom(): void {
  const el = document.getElementById('scroller');
  if (el !== null) {
    el.scrollTop = el.scrollHeight;
  }
}
export interface AnnouncementsConversationProps {
  announcements: Announcement[];
  onBack: () => void;
  hideConversations: boolean;
  onScrollTop: () => void;
  loadingMoreMessages: boolean;
  loadingCreateAnnouncement: boolean;
  inputMessage: string;
  setInputMessage: (value: string) => void;
  onSend: () => void;
  totalAnnouncements: number;
}

const AnnouncementsConversation: FunctionComponent<AnnouncementsConversationProps> = ({
  announcements,
  onBack,
  hideConversations,
  onScrollTop,
  loadingMoreMessages,
  loadingCreateAnnouncement,
  inputMessage,
  setInputMessage,
  onSend,
  totalAnnouncements
}) => {
  const messageInputElement = useRef<HTMLTextAreaElement>(null);
  const [lastScrollPosition, setScrollPosition] = useState(0);
  const lastScrollPositionRef = useRef(lastScrollPosition);
  const [inTop, setInTop] = useState(false);

  /**
   * Side effects
   */
  // This effect keeps the scroll to the bottom if you are neat it or if the message is from yourself
  const mostRecentAnnouncement = announcements[announcements.length - 1];
  useEffect(() => {
    const scroller = document.getElementById('scroller');
    if ((scroller?.scrollHeight ?? 0) - 1500 <= (scroller?.scrollTop ?? 0)) {
      scrollBottom();
    }
  }, [mostRecentAnnouncement ? mostRecentAnnouncement.id : null]);

  // This effects only keeps the ref's value in sync
  useEffect(() => {
    lastScrollPositionRef.current = lastScrollPosition;
  }, [lastScrollPosition]);

  // Effect that mantains scroll position when new messages appear
  useEffect(() => {
    const scroller = document.getElementById('scroller');
    if (loadingMoreMessages) {
      setScrollPosition((scroller?.scrollHeight ?? 0) - (scroller?.scrollTop ?? 0));
    } else if (scroller) {
      scroller.scrollTop = (scroller?.scrollHeight ?? 0) - lastScrollPositionRef.current;
    }
  }, [loadingMoreMessages]);

  // Scroll to the bottom only when the last message is different
  useEffect(() => {
    scrollBottom();
  }, []);

  /**
   * Events handlers and helpers
   */
  const onScroll = (): void => {
    if (loadingMoreMessages) return;
    const scroller = document.getElementById('scroller');
    if (scroller?.scrollTop === 0) {
      onScrollTop();
    }
    setInTop((scroller?.scrollHeight ?? 0) - (scroller?.scrollTop ?? 0) > 1500);
  };

  const { showConfirmationDialog } = useConfirmationDialog();

  const confirmSend = (): void => {
    showConfirmationDialog('¿Deseas enviar este anuncio ahora?', onSend);
  };

  return (
    <div className={hideConversations ? 'active-conversation' : 'active-conversation hide'}>
      <section className='person flex justify-left p-2'>
        <div className='return-button my-auto mr-3 cursor-pointer hover:' onClick={onBack}>
          <LeftArrow fill='#02b9cf' height='40' width='20' />
        </div>
        <div className='user-photo flex items-center'>
          <AyzerLogo height='3rem' width='3rem' />
          <div className='conversation-info flex flex-col items-left ml-2 justify-center'>
            <span>INFORMES AYZER</span>
          </div>
        </div>
      </section>

      <section className='scroller items-center conversation flex flex-col' id='scroller' onScroll={onScroll}>
        <div className='whitespace my-3'></div>
        {totalAnnouncements > announcements.length ? (
          <div
            className={
              'spinner flex justify-center items-center my-2 scale-0 transform transition-all duration-200 ' +
              (loadingMoreMessages && 'scale-100')
            }
          >
            <RawSpinner width='2em' height='2em' />
          </div>
        ) : (
          <span className='p-3 text-gray-600 text-center mb-3 max-w-sm mx-auto'>No hay más informes</span>
        )}
        {announcements.map((announcement: Announcement, index: number) => {
          let shouldAddDateTag = false;
          if (index !== 0) {
            const previousMessage = announcements[index - 1];
            shouldAddDateTag = !moment(announcement.createdAt).isSame(moment(previousMessage.createdAt), 'day');
          }
          return (
            <Fragment key={announcement.id}>
              {shouldAddDateTag && <DateMessage date={announcement.createdAt} />}
              <AnnouncementMessage key={announcement.id} announcement={announcement} />
            </Fragment>
          );
        })}
      </section>

      <section className='input-container flex p-4 pt-0 items-center'>
        <button
          className={
            'rounded-full shadow-md justify-center p-2 items-center absolute g-blue-button bottom-btn bottom-0 right-0 z-50 mr-6 mb-20 transform transition duration-200 ' +
            (inTop ? 'flex' : 'translate-x-32')
          }
          onClick={scrollBottom}
        >
          <ArrowDown width='0.5em' height='0.5em' />
        </button>
      </section>

      <Can do='createOneAnnouncement'>
        <section className='input-container flex p-4 pt-0 items-center'>
          <div className='text-area p-4 flex bg-white flex-col'>
            <div className='flex flex-1 items-center'>
              <button
                className={
                  'rounded-full shadow-md justify-center p-2 items-center absolute g-blue-button bottom-btn bottom-0 right-0 z-50 mr-6 mb-20 transform transition duration-200 ' +
                  (inTop ? 'flex' : 'translate-x-32')
                }
                onClick={scrollBottom}
              >
                <ArrowDown width='0.5em' height='0.5em' />
              </button>

              <textarea
                name={'message'}
                className={'message-input h-24 resize-none'}
                placeholder='Escribe un nuevo anuncio...'
                value={inputMessage}
                autoFocus
                ref={messageInputElement}
                maxLength={2000}
                onChange={({ target: { value: newVal } }): void => {
                  if (newVal.length > 2000) return;
                  const match = /\r|\n/.exec(newVal);
                  // If this is true, a newline was inserted as first character
                  if (match && match.index === 0) return;
                  if (!loadingCreateAnnouncement) {
                    setInputMessage(newVal);
                  }
                }}
              />

              <div
                className={
                  'send-button cursor-pointer flex transition duration-200 transform hover:scale-110 ' +
                  (inputMessage.length === 0 && 'opacity-25')
                }
                onClick={(): void => {
                  if (inputMessage.length === 0) return;
                  confirmSend();
                  scrollBottom();
                  messageInputElement.current?.focus();
                }}
              >
                <Send height='28px' width='28px' />
              </div>
            </div>

            <span className='text-gray-400 text-sm pt-1 z-10'>{inputMessage.length} / 2000</span>
          </div>
        </section>
      </Can>

      <style jsx>{`
        .active-conversation {
          margin: 0px 0px 0px 0px;
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        .return-button {
          padding-left: calc(20px - 0.5rem);
        }

        .notifications {
          border-bottom: black;
        }

        .person {
          display: flex;
          border-top: 2px solid #e8e8e8;
          border-bottom: 2px solid #e8e8e8;
        }

        textarea {
          border: none;
          padding: 0px;
          box-shadow: none;
          width: 100%;
          border-radius: 0;
        }

        textarea:focus {
          box-shadow: none;
        }

        .text-area {
          box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
          transition: box-shadow 0.08s ease-in, color 0.08s ease-in;
          width: 100%;
          border-radius: 1rem;
          padding: 0px 0px;
        }

        .conversation {
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          height: 100%;
          padding-left: 6%;
          padding-right: 6%;
        }

        .input-container {
          top: 0px;
        }

        .hide {
          display: flex;
          flex-direction: column;
        }

        .return-button {
          display: none;
        }

        input[type='file'] {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }

        @media only screen and (max-width: 640px) {
          .return-button {
            display: flex;
          }
          .active-conversation {
            position: fixed;
            left: 0;
            bottom: 0;
            top: 58px;
            right: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default AnnouncementsConversation;
