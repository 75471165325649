import { ApolloError } from '@apollo/client';
import { FunctionComponent } from 'react';
import Layout from './Layout';

type ErrorPageProps = {
  error?: ApolloError;
  statusCode?: number;
};

const ErrorPage: FunctionComponent<ErrorPageProps> = ({ statusCode }) => {
  let title: string, message: string;

  if (!statusCode) {
    title = 'Ha ocurrido un error desconocido';
    message =
      'Este error es inesperado, sin embargo, ya se ha enviado un reporte al equipo de soporte técnico. Si el error sigue sucediendo repórtalo.';
  } else {
    if (statusCode === 403) {
      title = 'No cuentas con permisos suficientes para acceder a esta sección';
      message = 'Si crees que hay un error en esto puedes contactar al soporte técnico.';
    } else if (statusCode === 404) {
      title = 'Lo sentimos, la ruta que buscas no existe';
      message = 'Si crees que hay un error en esto puedes contactar al soporte técnico.';
    } else if (statusCode.toString().startsWith('5')) {
      title = 'Error del servidor';
      message =
        'Este error es inesperado, sin embargo, ya se ha enviado un reporte al equipo de soporte técnico. Si el error sigue sucediendo repórtalo.';
    } else {
      title = 'Ha ocurrido un error desconocido';
      message =
        'Este error es inesperado, sin embargo, ya se ha enviado un reporte al equipo de soporte técnico. Si el error sigue sucediendo repórtalo.';
    }
  }

  return (
    <>
      <Layout title={`Error ${statusCode || 'desconocido'}`}>
        <section id='sectionError' className='container mx-auto'>
          <h1>{title}</h1>
          <p>{message}</p>
          {/* <a className='wa-btn' href={whatsAppLinkPredefined} target='_blank' rel='noopener noreferrer'>
            <span className='icon'>
              <WhatsAppIcon height='18px' width='18px' />
            </span>
            Enviar mensaje
          </a> */}
        </section>
      </Layout>
      <style jsx>{`
        .container {
          display: flex;
          height: 100vh;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        section > * {
          margin: 10px 0;
          text-align: center;
        }

        h1 {
          font-size: 2rem;
        }

        p {
          font-size: 1rem;
        }

        .error {
          font-size: 70px;
          font-weight: 700;
        }

        .wa-btn {
          border: 1px solid #00e676;
          color: #00e676;
          text-align: center;
          padding: 7px 14px;
          border-radius: 20px;
          width: fit-content;
          display: flex;
          align-items: center;
        }

        .wa-btn:hover {
          border: 1px solid #00e676;
          color: white;
          background: #00e676;
          transition: 0.2s;
        }

        .icon {
          margin-right: 15px;
          display: flex;
          align-items: center;
        }
      `}</style>
    </>
  );
};

export default ErrorPage;
