/* eslint-disable react-hooks/rules-of-hooks */
import { gql } from '@apollo/client';
import { useSwrQuery } from 'components/hooks/swrHooks';
import { useNotification } from 'context/NotificationContext';
import UserContext from 'context/UserContext';
import { ChatType, Employee, EmployeeWhereInput, Patient, PatientWhereInput, Role } from 'generated/schema-types';
import { Dispatch, FunctionComponent, SetStateAction, useContext } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import NewChatButton from './NewChatButton';

interface GetEmployeesResult {
  employees: Employee[];
}
interface GetEmployeesVariables {
  userId: string;
  completeFilter: EmployeeWhereInput[];
  chatParticipantsIds: string[];
  skip: number;
  take: number;
  filter: string;
}
const GET_EMPLOYEES_QUERY = gql`
  query getEmployees(
    $userId: String!
    $completeFilter: [EmployeeWhereInput!]!
    $chatParticipantsIds: [String!]!
    $skip: Int!
    $take: Int!
    $filter: String!
  ) {
    employees(
      orderBy: { createdAt: desc }
      where: {
        chatParticipant: { id: { not: { in: $chatParticipantsIds } } }
        name: { contains: $filter }
        active: { equals: true }
        id: { not: { equals: $userId } }
        OR: $completeFilter
      }
      skip: $skip
      take: $take
    ) {
      id
      name
      photo
    }
  }
`;

interface GetPatientsResult {
  patients: Patient[];
}
interface GetPatientsVariables {
  completeFilter: PatientWhereInput[];
  chatParticipantsIds: string[];
  skip: number;
  take: number;
  branches: string[];
  filter: string;
}
const GET_PATIENTS_QUERY = gql`
  query getPatients(
    $chatParticipantsIds: [String!]!
    $skip: Int!
    $take: Int!
    $branches: [String!]!
    $filter: String!
  ) {
    patients(
      orderBy: { createdAt: desc }
      where: {
        chatParticipant: { id: { not: { in: $chatParticipantsIds } } }
        name: { contains: $filter }
        active: { equals: true }
        branch: { id: { in: $branches } }
      }
      skip: $skip
      take: $take
    ) {
      id
      name
      photo
      branch {
        id
      }
    }
    count {
      patient(where: { chatParticipant: { id: { not: { in: $chatParticipantsIds } } }, active: { equals: true } })
    }
  }
`;

export interface AddChatTabProps {
  onClick: (person: Patient | Employee) => void;
  chatParticipantsIds: string[];
  filter: string;
  chatType: ChatType;
  reachedBottom: boolean;
  setReachedBottom: Dispatch<SetStateAction<boolean>>;
}

const AddChatTab: FunctionComponent<AddChatTabProps> = ({
  onClick,
  chatParticipantsIds,
  filter,
  chatType,
  reachedBottom,
  setReachedBottom
}) => {
  const user = useContext(UserContext);
  const { newFeedbackNotification } = useNotification();

  const fetchAmount = 10;

  const allowedRoles = {
    ADMIN: [
      [Role.ADMIN, 'global'],
      [Role.COACH, 'global'],
      [Role.COORDINATOR, 'global'],
      [Role.DOCTOR, 'local'],
      [Role.RECEPTIONIST, 'local'],
      [Role.DENTAL_ASSISTANT, 'local']
    ],
    COACH: [
      [Role.ADMIN, 'local'],
      [Role.COACH, 'global'],
      [Role.COORDINATOR, 'local']
    ],
    COORDINATOR: [
      [Role.ADMIN, 'global'],
      [Role.COACH, 'local'],
      [Role.COORDINATOR, 'global']
    ],
    DOCTOR: [
      [Role.ADMIN, 'local'],
      [Role.COACH, 'local'],
      [Role.COORDINATOR, 'local'],
      [Role.DENTAL_ASSISTANT, 'local'],
      [Role.DOCTOR, 'local'],
      [Role.RECEPTIONIST, 'local']
    ],
    DENTAL_ASSISTANT: [
      [Role.ADMIN, 'local'],
      [Role.COACH, 'local'],
      [Role.COORDINATOR, 'local'],
      [Role.DENTAL_ASSISTANT, 'local'],
      [Role.DOCTOR, 'local'],
      [Role.RECEPTIONIST, 'local']
    ],
    RECEPTIONIST: [
      [Role.ADMIN, 'local'],
      [Role.COACH, 'local'],
      [Role.COORDINATOR, 'local'],
      [Role.DENTAL_ASSISTANT, 'local'],
      [Role.DOCTOR, 'local'],
      [Role.RECEPTIONIST, 'local']
    ]
  };
  if (!user) return null;
  const employeesFilter: EmployeeWhereInput[] = allowedRoles[user?.role].map(role => ({
    AND: [
      { role: { equals: role[0] as Role } },
      { branches: role[1] === 'global' ? {} : { some: { id: { in: user?.branches.map(branch => branch.id) } } } }
    ]
  }));
  const patientsFilter: PatientWhereInput[] = [];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { loading, data, fetchMore } = useSwrQuery<GetEmployeesResult, GetEmployeesVariables>(GET_EMPLOYEES_QUERY, {
    variables: {
      chatParticipantsIds: chatParticipantsIds,
      userId: user?.id ?? '',
      completeFilter: employeesFilter,
      skip: 0,
      take: fetchAmount,
      filter: filter
    },
    onError: () => {
      newFeedbackNotification({
        level: 'error',
        message: 'Hubo un problema al obtener los empleados.'
      });
    }
  });

  const { loading: loadingPatients, data: patientsData, fetchMore: fetchMorePatients } = useSwrQuery<
    GetPatientsResult,
    GetPatientsVariables
  >(GET_PATIENTS_QUERY, {
    variables: {
      chatParticipantsIds: chatParticipantsIds,
      completeFilter: patientsFilter,
      skip: 0,
      take: fetchAmount,
      branches: user.branches.map(b => b.id),
      filter: filter
    },
    onError: () => {
      newFeedbackNotification({
        level: 'error',
        message: 'Hubo un problema al obtener los empleados.'
      });
    }
  });

  let persons: (Employee | Patient)[] | null = null;
  if (chatType === ChatType.EMPLOYEE_ON_EMPLOYEE && !loading && data?.employees) {
    persons = data?.employees;
  } else if (chatType === ChatType.BRANCH_ON_PATIENT && !loadingPatients && patientsData?.patients) {
    persons = patientsData?.patients;
  }

  const fetchMoreEmployeesCall = async (): Promise<void> => {
    setReachedBottom(false);

    await fetchMore({
      query: GET_EMPLOYEES_QUERY,
      variables: {
        chatParticipantsIds: chatParticipantsIds,
        userId: user.id,
        completeFilter: employeesFilter,
        skip: data?.employees.length,
        take: fetchAmount,
        filter: filter
      },
      updateQuery: (prev: GetEmployeesResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          employees: [...prev.employees, ...fetchMoreResult.employees]
        });
      }
    });
  };

  const fetchMorePatientsCall = async (): Promise<void> => {
    setReachedBottom(false);
    await fetchMorePatients({
      query: GET_PATIENTS_QUERY,
      variables: {
        chatParticipantsIds: chatParticipantsIds,
        completeFilter: patientsFilter,
        skip: patientsData?.patients.length,
        take: fetchAmount,
        branches: user.branches.map(b => b.id),
        filter: filter
      },
      updateQuery: (prev: GetPatientsResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          patients: [...prev.patients, ...fetchMoreResult.patients]
        });
      }
    });
  };

  if (reachedBottom && chatType === ChatType.EMPLOYEE_ON_EMPLOYEE && data) {
    fetchMoreEmployeesCall();
  } else if (reachedBottom && chatType === ChatType.BRANCH_ON_PATIENT && patientsData) {
    fetchMorePatientsCall();
  }

  return (
    <div className='add-chats'>
      {loading || loadingPatients ? (
        <LoadingSpinner loading />
      ) : (
        <div>
          {persons && persons.length === 0 && (
            <span className='p-6 text-center flex justify-center text-gray-600'>No se encontraron empleados</span>
          )}
          {persons &&
            persons.map(person => <NewChatButton key={person.id} person={person} onClick={() => onClick(person)} />)}
        </div>
      )}
    </div>
  );
};

export default AddChatTab;
