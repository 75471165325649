import { FunctionComponent } from 'react';
import ProtectedImage from 'components/ProtectedImage';
import { Employee, Patient } from 'generated/schema-types';
import { UserIcon } from '../../../public/icons';

type NewChatButtonProps = {
  person: Employee | Patient;
  onClick: (id: string) => void;
};

const NewChatButton: FunctionComponent<NewChatButtonProps> = ({ person, onClick }) => {
  return (
    <div
      className='cursor-pointer employee-to-add p-4 mb-4 bg-gray-100 shadow-md rounded-lg flex items-center transition-all duration-200 hover:scale-105 transform'
      onClick={(): unknown => onClick(person.id)}
    >
      {person.photo ? (
        <div className='h-12 w-12 flex'>
          <ProtectedImage
            className='rounded-full flex'
            filename={person.photo}
            imgStyle={{ width: '100%', height: '100%' }}
            objectFit='cover'
            size='60x60'
          />
        </div>
      ) : (
        <UserIcon height='3rem' width='3rem' fill='rgb(97,97,97)' />
      )}
      <div className=' ml-3 text-gray-700'>{person.name}</div>
    </div>
  );
};

export default NewChatButton;
