import { gql } from '@apollo/client';
import { useSwrQuery } from 'components/hooks/swrHooks';
import { FunctionComponent } from 'react';
import { useNotification } from '../context/NotificationContext';

const GET_SIGNED_URL = gql`
  query signedUrl($filename: String!) {
    signedUrl(filename: $filename) {
      url
    }
  }
`;

export interface DownloadButtonProps {
  filename: string;
  name: string;
}

const DownloadButton: FunctionComponent<DownloadButtonProps> = ({ filename, children, name }) => {
  const { newFeedbackNotification } = useNotification();

  const { data, loading } = useSwrQuery(GET_SIGNED_URL, {
    variables: { filename },
    onError: () => {
      newFeedbackNotification({
        level: 'error',
        message: 'Hubo un problema al obtener archivo.'
      });
    }
  });

  const downloadFile = async (): Promise<void> => {
    try {
      const res = await fetch(data.signedUrl.url, {
        method: 'GET',
        headers: {
          'cache-control': 'no-cache'
        }
      });

      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (error) {
      console.log('downloadFile error:', error);
    }
  };

  return (
    <a className='g-orange-button' onClick={loading ? (): void => console.log('loading') : downloadFile} href={'#'}>
      {children}
    </a>
  );
};

export default DownloadButton;
