import { ExclamationIcon } from '../../public/icons';

const IssueButton: React.FC = () => {
  return (
    <>
      <a
        href='https://gitlab.com/positive-zero/ayzer-dental/ayzer-dental-system/-/issues/new?issue%5Bassignee_id%5D=&issue%5Bmilestone_id%5D='
        rel='noopener noreferrer'
        target='_blank'
      >
        <div className='group issue flex fixed top-0 sm:bottom-0 sm:top-auto right-0 m-4 items-center opacity-50 hover:opacity-100 transition-all duration-150'>
          <div className='text-xs transition-all transform opacity-0 group-hover:opacity-100 translate-x-24 group-hover:translate-x-0 duration-150 mr-2 scale-x-90'>
            Crear issue
          </div>
          <div className='flex bg-gray-200 rounded-full shadow-xl p-2 hover:scale-125 transform transition-all duration-150'>
            <ExclamationIcon />
          </div>
        </div>
      </a>
      <style jsx>{`
        .issue {
          z-index: 9999;
        }
      `}</style>
    </>
  );
};

export default IssueButton;
