import { FunctionComponent } from 'react';
import { NotificationIcon } from '../../public/icons';

interface NotificationBellProps {
  newNotifications: number;
  showList: boolean;
  onChangeShowList: (value: boolean) => void;
}

const NotificationBell: FunctionComponent<NotificationBellProps> = ({
  newNotifications,
  showList: showList,
  onChangeShowList: onChangeShowList
}) => {
  const handleBell = (): void => {
    onChangeShowList(!showList);
  };

  return (
    <>
      <button
        className='notification-bell flex items-center justify-center relative rounded-full'
        onClick={(): unknown => handleBell()}
      >
        <div>
          <div
            className={
              'unread absolute top-0 right-0 rounded-full p-1 bg-primary text-xs flex justify-center items-center text-white w-5 h-5 transition-all transform duration-500 ' +
              (newNotifications > 0 ? 'scale-100' : 'scale-0')
            }
          >
            {newNotifications}
          </div>
        </div>
        <NotificationIcon width='25' height='25' />
      </button>

      <style jsx>{`
        .notification-bell {
          list-style: none;
          padding: 5px;
          text-align: center;
          transform: translate(2px);
          cursor: pointer;
          width: max-content;
        }
      `}</style>
    </>
  );
};

export default NotificationBell;
