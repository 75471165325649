import { numericDateFormat, hour } from '../../utils/dateFormats';
import moment from 'moment';
import { FunctionComponent, useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';
import { Announcement } from 'generated/schema-types';
import AnnouncementViews from './AnnouncementViews';
import Can from 'components/Can';

const AnnouncementMessage: FunctionComponent<{
  announcement: Announcement;
}> = ({ announcement }) => {
  const sentToday = moment(announcement.createdAt).isSame(moment(), 'day');
  const sentYesterday = moment(announcement.createdAt).isSame(moment().add(-1, 'days'), 'day');
  const showDialog = useContext(ModalContext);

  const openViews = (): void => {
    showDialog(<AnnouncementViews employeeIds={announcement.announcementViews.map(a => a.employeeId)} />);
  };

  let dateTag;
  if (sentToday) {
    dateTag = hour(new Date(announcement.createdAt));
  } else if (sentYesterday) {
    dateTag = 'Ayer' + ', ' + hour(new Date(announcement.createdAt)).toString();
  } else {
    dateTag =
      numericDateFormat(new Date(announcement.createdAt)).toString() +
      ', ' +
      hour(new Date(announcement.createdAt)).toString();
  }

  return (
    <>
      <div className={'message rounded-lg bg-white shadow-xs text-gray-900 flex flex-col mb-1 other'}>
        <pre style={{ wordBreak: 'break-word' }} className={`text-gray-800 break-words text-sm`}>
          {announcement.content}
        </pre>

        <Can do='announcementViews'>
          <a className='cursor-pointer hover:underline text-gray-500 text-sm' onClick={(): void => openViews()}>
            Ver vistos
          </a>
        </Can>

        <div className='justify-right text-right'>
          <span className={'text-gray-500 text-xs flex items-center ml-auto '}>
            <small>{dateTag}</small>
          </span>
        </div>
      </div>

      <style jsx>{`
        .message {
          word-wrap: break-word;
          max-width: 80%;
          padding: 10px;
          flex: none;
        }

        .img {
          max-height: 500px;
          max-width: 600px;
          min-height: 300px;
          min-width: 300px;
        }

        @media only screen and (max-width: 950px) {
          .img {
            min-height: 200px;
            min-width: 100px;
          }
        }
      `}</style>
    </>
  );
};

export default AnnouncementMessage;
