import Link from 'next/link';
import React, { FunctionComponent } from 'react';
import {
  AgendaIcon,
  ChatIcon,
  DashboardIcon,
  GestionIcon,
  LogoIcon,
  PacientesIcon,
  ProfileIcon,
  ReportesIcon,
  TimesIcon
} from '../../public/icons';
import HelpIcon from '../../public/icons/info.svg';
import Can from './Can';
import NotificationBell from './NotificationBell';

interface SidebarItemProps {
  currentSection?: string;
  title: string;
  icon: SvgrComponent;
  iconSize: string;
  route: string;
}

const SidebarItem: FunctionComponent<SidebarItemProps> = ({ currentSection, title, icon: Icon, iconSize, route }) => {
  let isSelected = currentSection === route;
  const isLogo = title === 'Inicio';
  isLogo && (isSelected = false);

  return (
    <>
      <Link href={route}>
        <a
          className={`item flex flex-col items-center justify-center link-${title}`}
          style={{ display: 'flex', justifyContent: 'center' }}
          title={title}
        >
          <Icon width={iconSize} height={iconSize} color={isSelected ? '#00b6cd' : '#878789'} />
        </a>
      </Link>

      <style jsx>{`
        .item {
          list-style: none;
          margin: 20px auto;
          padding: 5px;
          text-align: center;
          width: 100%;
          border-left: 1px solid transparent;
          transform: translate(2px);
          border-right: 3px solid ${isSelected ? '#00b6cd' : 'transparent'};
        }
        .item:hover,
        .item:focus {
          border-right: 3px solid ${title === 'Inicio' ? 'transparent' : '#00b6cd'};
        }

        @media screen and (max-width: 640px) {
          .item .link-Inicio {
            display: none !important;
          }
        }
      `}</style>
    </>
  );
};

interface SidebarProps {
  currentSection?: string;
  menuDisplayed: boolean;
  onCloseMenu: () => void;
  navbarBackgroundColor?: string;
  notificationListDisplayed: boolean;
  newNotifications: number;
  setNotificationListDisplayed: (value: boolean) => unknown;
}

const Sidebar: FunctionComponent<SidebarProps> = ({
  currentSection,
  menuDisplayed,
  onCloseMenu,
  navbarBackgroundColor,
  notificationListDisplayed,
  newNotifications,
  setNotificationListDisplayed
}) => {
  const iconSize = '25px';

  return (
    <div className={`sidebar-background duration-300 transition-transform ${menuDisplayed && 'animation shadow-lg'}`}>
      <div className={`sidebar`}>
        <div className='navbar'>
          {menuDisplayed && (
            <div className='w-full p-1 flex justify-center mt-2 cursor-pointer' onClick={onCloseMenu}>
              <TimesIcon width='20px' height='20px' color='#5d5858' />
            </div>
          )}
          <SidebarItem currentSection={currentSection} route='/' title='Inicio' icon={LogoIcon} iconSize={iconSize} />
          <SidebarItem
            currentSection={currentSection}
            route='/'
            title='Dashboard'
            icon={DashboardIcon}
            iconSize={iconSize}
          />
          <SidebarItem
            currentSection={currentSection}
            route='/pacientes'
            title='Pacientes'
            icon={PacientesIcon}
            iconSize={iconSize}
          />
          <SidebarItem
            currentSection={currentSection}
            route='/agenda'
            title='Agenda'
            icon={AgendaIcon}
            iconSize={iconSize}
          />
          <Can do='reports'>
            <SidebarItem
              currentSection={currentSection}
              route='/reportes'
              title='Reportes'
              icon={ReportesIcon}
              iconSize={iconSize}
            />
          </Can>
          <Can do='management'>
            <SidebarItem
              currentSection={currentSection}
              route='/gestion'
              title='Gestion'
              icon={GestionIcon}
              iconSize={iconSize}
            />
          </Can>
          <Can do='chats'>
            <SidebarItem
              currentSection={currentSection}
              route='/mensajes'
              title='Mensajes'
              icon={ChatIcon}
              iconSize={iconSize}
            />
          </Can>
        </div>
        <div className='acount-settings'>
          <span className='notification-bell g-container rounded-full'>
            <NotificationBell
              showList={notificationListDisplayed}
              onChangeShowList={setNotificationListDisplayed}
              newNotifications={newNotifications}
            />
          </span>
          <SidebarItem
            currentSection={currentSection}
            route='/perfil'
            title='Perfíl'
            icon={ProfileIcon}
            iconSize={iconSize}
          />
          <SidebarItem
            currentSection={currentSection}
            route='/ayuda'
            title='Ayuda'
            icon={HelpIcon}
            iconSize={iconSize}
          />
        </div>
      </div>

      <style jsx>{`
        .sidebar-background {
          background-color: ${navbarBackgroundColor ? navbarBackgroundColor : 'none'};
          position: fixed;
          height: 100vh;
          width: 70px;
          z-index: 20;
        }
        .sidebar {
          flex: none;
          height: 100%;
          width: 100%;
          align-items: center;
          background-color: #ffffff;
          border-bottom-right-radius: 35px;
          border-bottom: 2px solid #e4e4e4;
          border-right: 2px solid #e4e4e4;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .sidebar div {
          width: 100%;
          padding-top: 10px;
        }

        .navbar {
          margin-top: 5px;
        }

        .acount-settings {
          margin-bottom: 5px;
        }

        @media screen and (max-width: 640px) {
          .sidebar-background {
            z-index: 20;
            transform: translateX(-70px);
            top: 0;
          }
          .navbar {
            margin-top: 0;
          }
          .animation {
            transform: translateX(0);
          }
          .link-Inicio {
            display: none;
          }
          .notification-bell {
            display: none;
          }
        }

        @media screen and (max-height: 560px) {
          .sidebar-background {
            position: absolute;
          }
          .sidebar {
            height: max-content;
          }
        }
      `}</style>
    </div>
  );
};

export default Sidebar;
