import DownloadButton from 'components/DownloadButton';
import ProtectedImage from 'components/ProtectedImage';
import { GestionIcon } from '../../../public/icons';

export interface FullImageProps {
  filename: string;
  onDelete: () => void;
  mine: boolean;
  mimetype: string;
  name: string;
}

const AttachmentView: React.FC<FullImageProps> = ({ filename, onDelete, mine, mimetype, name }) => {
  const newName = name || 'Archivo sin nombre';
  return (
    <div className='full flex flex-col space-y-5 items-center'>
      <div className='overflow-auto shadow-xl'>
        {mimetype && mimetype.startsWith('image/') ? (
          <ProtectedImage
            filename={filename}
            imgStyle={{ margin: 'auto', maxWidth: '75vw', maxHeight: '75vh' }}
            objectFit='contain'
            size='800x480'
          />
        ) : (
          <div className='rounded-md shadow-2xl thumbnail bg-gray-100 p-4 flex justify-center items-center text-gray-700'>
            <div className='flex-'>
              <GestionIcon className='text-gray-600' width='5em' height='5em' />
            </div>
            <span className='ml-2 text-base max-w-xs text-gray-600'>{newName}</span>
          </div>
        )}
      </div>

      <div className='flex justify-center items-center space-x-3'>
        <DownloadButton filename={filename} name={newName}>
          Descargar
        </DownloadButton>
        {mine && (
          <button className='g-blue-button' onClick={onDelete}>
            Eliminar
          </button>
        )}
      </div>
    </div>
  );
};

export default AttachmentView;
